import { useQuery } from '@tanstack/react-query';
import { attemptGetOrgLocationsByUser } from '../../api/OrgApi';
import { attemptGetOrgLocationsMetrics } from '../../api/OrgApi';
import { toast } from 'react-toastify';

const fetchOrgLocationsByUser = async (userId) => {
    return attemptGetOrgLocationsByUser(userId).then((res) => res.data['data']['locations']);
};

const fetchOrgLocationsMetrics = async (data, metricType) => {
    return attemptGetOrgLocationsMetrics(data, metricType).then((res) => {
        if (metricType === "total_new_return_connect") {
            return res.data
        } else if (metricType === "new_return_connect_location") {
            return res.data
        } else if (metricType === "connection_metric_card") {
            return res.data["data"]
        }
    })
};

export const useGetOrgLocationsByUser = (userId) => {
    return useQuery({
        queryKey: ['org-locations-by-user', userId],
        queryFn: () => fetchOrgLocationsByUser(userId),
        onError: (error) => {
            toast.error(`Error fetching locations: ${error.message}`);
        },
        enabled: !!userId,
    });
};

export const useGetOrgLocationsMetrics = (data, metricType, enabled) => {
    return useQuery({
        queryKey: ['org-locations-metrics', data, metricType],
        queryFn: () => fetchOrgLocationsMetrics(data, metricType),
        enabled,
        onError: (error) => {
            toast.error(`Error fetching locations metrics: ${error.message}`);
        },
    });
}