import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { differenceInDays, startOfDay, isEqual } from 'date-fns';
import { Text, Flex, Separator, Badge, Tooltip, IconButton, Button } from "@radix-ui/themes";
import { GearIcon } from '@radix-ui/react-icons';
import { Header, LiveConnectionButton, AvatarDropdownMenu } from "components";
import { useLocations, useDateRange, useLiveConnectionStats, useMetricsData, useProfile } from "hooks";
import { DatePickerWithRange } from "components/datepicker/DatePickerWithRange";
import { LiveStatCard } from "components/card/LiveStatCard";
import { GuestConnectionsChart } from "components/charts/GuestConnectionsChart";
import { GuestConnectionsByTimeOfDayChart } from "components/charts/GuestConnectionsByTimeOfDayChart";
import { GuestConnectionsByDayChart } from "components/charts/GuestConnectionsByDayChart";
import { GuestConnectionsByDayOfWeekChart } from "components/charts/GuestConnectionsByDayOfWeekChart";
import { GuestConnectionsByMonthChart } from "components/charts/GuestConnectionsByMonthChart";
import { HeatmapChart } from "components/charts/HeatmapChart";
import { BarChart } from "components/charts/BarChart";
import { GuestConnectionsByLocationsChart } from "components/charts/GuestConnectionsByLocationsChart";
import { UniqueGuestsByAgeChart } from "components/charts/UniqueGuestsByAgeChart";
import { UniqueGuestsByGenderChart } from "components/charts/UniqueGuestsByGenderChart";
import { LineChart } from "components/charts/LineChart";
import { MetricCard } from "components/card/MetricCard";
import { ChartCard } from "components/card/ChartCard";
import { UsersIcon, UserIcon, ArrowUturnLeftIcon, PlusIcon, ClockIcon } from "@heroicons/react/16/solid"
import  LocationMultiSelect from "components/locationMultiSelect/LocationMultiSelect";
import { useTheme } from "../../context/ThemeContext";
import  NewReturningLegend  from "components/charts/NewReturningLegend";



export default function Dashboard() {
  // Navigation hook
  const navigate = useNavigate();

  const { data: profile} = useProfile();

  // Create states for user role and id for it to be accessible outside of the useEffect block
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    if (profile) {
      setUserId(profile.id);
      setUserRole(profile.user_role);
    }
  }, [profile]);

  // Set the default date to September 1, 2024
  const default_date = new Date('2024-09-01');


  // Custom hooks
  const { initialItems, locationSelection, setLocationSelection, locationsData, isLocationsSuccess } = useLocations(userId);
  const { selectedDateRange, handleDateRangeChange } = useDateRange();
  const { liveConnectionStat } = useLiveConnectionStats(locationSelection, locationsData);
  const { 
    metricsData,
    guestConnectionsChartData, 
    guestConnectionsByLocationsChartData 
  } = useMetricsData(selectedDateRange, locationSelection, userId, isLocationsSuccess);

  const { setAppearance } = useTheme();
  const [showLiveConnectionCards, setShowLiveConnectionCards] = useState(true);

  const dateRangeDifference = differenceInDays(selectedDateRange.to, selectedDateRange.from) + 1;

  const toggleLiveConnectionCards = () => setShowLiveConnectionCards(locationSelection.length > 0 ? !showLiveConnectionCards : false);

  // Use useEffect to set toggleLiveConnectionCards to false when locationSelection is empty

  useEffect(() => {
    if (locationSelection.length === 0) {
      setShowLiveConnectionCards(false);
    } else {
      setShowLiveConnectionCards(true);
    }
  }, [locationSelection]);

  var metricTileDataOne = [
    {
      name: "Guest Connections",
      icon: <UsersIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: metricsData?.guest_connections ?? 'Loading...',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_gc_change >= 0 ? '+' : ''}${metricsData?.last_period_gc_change ?? '...'}% since last period`,
      tooltip: 'Total guest connections in the selected time range.'
    },
    {
      name: "Unique Guests",
      icon: <UserIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: metricsData?.unique_guests ?? 'Loading...',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_ug_change >= 0 ? '+' : ''}${metricsData?.last_period_ug_change ?? '...'}% since last period`,
      tooltip: 'Distinct guests connected in the time range.'
    },
    {
      name: "Unique Returning Guests",
      icon: <ArrowUturnLeftIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: metricsData?.unique_returning_guests ?? 'Loading...',
      subMetric: metricsData?.unique_guests && metricsData?.unique_returning_guests
        ? `${((metricsData.unique_returning_guests / metricsData.unique_guests) * 100).toFixed(1)}%`
        : '0.0%',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_urg_change >= 0 ? '+' : ''}${metricsData?.last_period_urg_change ?? '...'}% since last period`,
      tooltip: 'Distinct returning guests that connected during the time range.'
    },
    {
      name: "Unique New Guests", 
      icon: <PlusIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: metricsData?.unique_new_guests ?? 'Loading...',
      subMetric: metricsData?.unique_guests && metricsData?.unique_new_guests
        ? `${((metricsData.unique_new_guests / metricsData.unique_guests) * 100).toFixed(1)}%`
        : '0.0%',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_ung_change >= 0 ? '+' : ''}${metricsData?.last_period_ung_change ?? '...'}% since last period`,
      tooltip: 'Distinct new guests that connected during the time range.'
    },
  ];

  var metricTileDataTwo = [
    {
      name: "Avg. Time of Connection", 
      icon: <ClockIcon style={{width: '16px', height: '16px', color: 'gray'}} />,
      metric: `${metricsData?.average_connection_duration ?? 'Loading...'} mins`,
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_acd_change >= 0 ? '+' : ''}${metricsData?.last_period_acd_change ?? '...'}% since last period`,
      tooltip: 'Average connection time per guest.'
    },
    {
      name: "Avg. Hourly Connections",
      metric: metricsData?.average_hourly_connections ?? 'Loading...',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : `${metricsData?.last_period_ahc_change >= 0 ? '+' : ''}${metricsData?.last_period_ahc_change ?? '...'}% since last period`,
      tooltip: 'Average guest connections per hour.'
    },
    {
      name: "Avg. Daily Connections",
      metric: (dateRangeDifference > 1) ? metricsData?.average_daily_connections ?? 'Loading...' : '',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : (dateRangeDifference > 1) ? `${metricsData?.last_period_adc_change >= 0 ? '+' : ''}${metricsData?.last_period_adc_change ?? '...'}% since last period` : 'Select a multi-day range to see Avg. Daily Connections',
      tooltip: 'Average guest connections per day.'
    },
    {
      name: "Avg. Weekly Connections",
      metric: (dateRangeDifference > 6) ? metricsData?.average_weekly_connections ?? 'Loading...' : '',
      updates: isEqual(startOfDay(selectedDateRange.from), startOfDay(metricsData?.disable_before_date))
        ? 'The last period does not have data' 
        : (dateRangeDifference > 6) ? `${metricsData?.last_period_awc_change >= 0 ? '+' : ''}${metricsData?.last_period_awc_change ?? '...'}% since last period` : 'Select a multi-week range to see Avg. Weekly Connections',
      tooltip: 'Average guest connections per week.'
    }
  ];


  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const selectedDateRangeInDays = Math.ceil((selectedDateRange.to.getTime() - selectedDateRange.from.getTime()) / oneDayInMilliseconds);

  return (
    <React.Fragment>
      <div style={{backgroundColor: "var(--color-background)" }} >
      <div style={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "var(--color-panel-solid)" }}>
        <Header >
          <Flex display="flex" align="center" gap="4">
            <Text className="" size="4" weight="bold">
              Guest Wi-Fi
            </Text>
            <Tooltip content="This product is still in development and so you may encounter issues.">
              <Badge variant="soft" size="1" color="gray">
                Beta
              </Badge>
            </Tooltip>
            {userRole === 'Owner' && (
              <Button size="1" variant="ghost" color="gray" style={{cursor: "pointer" }}
                onClick={() => navigate("/guest-wifi-settings")}>
                <GearIcon />
                Settings
              </Button>
            )}
          </Flex>
          <Flex display="flex" align="center" gap="4">
            <div>
              <LiveConnectionButton 
                onClick={toggleLiveConnectionCards}
                showLiveConnectionCards={showLiveConnectionCards} />
            </div>
            {/* <div>
              <DeviceGroupingSwitch />
            </div> */}
            <div className="card flex justify-content-center">
              <LocationMultiSelect
                options={initialItems}
                defaultValue={locationSelection}
                onValueChange={setLocationSelection}
              />
            </div>
            <div className="flex flex-row gap-2">
              <DatePickerWithRange 
                value={selectedDateRange} 
                onDateChange={handleDateRangeChange}
                disableBeforeDate={metricsData?.disable_before_date ?? default_date}/>
            </div>
            <div>
              <AvatarDropdownMenu/>
            </div>
          </Flex>
        </Header>
        <Separator size="4" orientation="horizontal" />
        <Flex
          style={{
            alignSelf: "stretch",
            position: "sticky",
            top: "0px",
            zIndex: 1000,
            backgroundColor: "var(--color-panel-solid)",
          }}
          display="flex"
          align="start"
          direction="row"
          width="100%"
        >
          <div
            style={{
              maxHeight: showLiveConnectionCards ? "500px" : "0px",
              overflow: "hidden",
              transition: "max-height 0.8s ease-in-out",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {showLiveConnectionCards && liveConnectionStat.map((entry, index) => (
              <LiveStatCard
                key={index}
                title={entry.title}
                value={entry.value}
                subValue={entry.subValue}
                separator={entry.separator}
                badge={entry.badge}
                isLast={index === liveConnectionStat.length - 1} 
                userRole={userRole}
              />
            ))}
          </div>
        </Flex>
        {showLiveConnectionCards && <Separator size="4" orientation="horizontal" />}
      </div>
      {locationSelection.length === 0 ? (
        <Flex
          display="flex"
          direction="column"
          align="center"
          justify="center"
          height="80vh"
          gap="2"
        >
          <Text
            size="5"
            weight="medium"
            style={{
              color: 'var(--gray-12)',
            }}
          >
            No Location Selected
          </Text>
          <Text
            size="2"
            weight="regular"
            style={{
              color: 'var(--gray-12)',
            }}
          >
            Select a Location to see results
          </Text>
        </Flex>
      ) : (
        <React.Fragment>
          <Flex
            display="flex"
            direction="column"
            align="center"
            gap="5"
            p="5"
          >
            <Flex
              display="flex"
              height="180px"
              width='100%'
              maxWidth="1000px"   
              gap="5"
              direction="row"
              justify="center"
            >
              {metricTileDataOne.map((entry, index) => (
                <MetricCard key={index}
                  metricName={entry.name}
                  metricIcon={entry.icon}
                  metric={entry.metric}
                  subMetric={entry.subMetric}
                  updates={entry.updates}
                  tooltip={entry.tooltip}
                />
              ))}
            </Flex>
            {selectedDateRangeInDays === 1 && (
        <ChartCard 
          text="Guest connections by time of day" 
          tooltip="Breakdown of guest connections over time."
          legend={<NewReturningLegend payload={[
            { value: 'new', color: "var(--grass-9)" },
            { value: 'returning', color: "var(--amber-9)" }
          ]} />}
        >
          <GuestConnectionsByTimeOfDayChart 
            data={guestConnectionsChartData} 
            startTimeMilliseconds={selectedDateRange.from.getTime()} 
            endTimeMilliseconds={selectedDateRange.to.getTime()} 
          />
        </ChartCard>
      )}

{selectedDateRangeInDays > 1 && selectedDateRangeInDays < 62 && (
  <React.Fragment>
    <ChartCard 
      text="Guest connections by day" 
      tooltip="Breakdown of guest connections by day."
      legend={<NewReturningLegend payload={[
        { value: 'new', color: "var(--grass-9)" },
        { value: 'returning', color: "var(--amber-9)" }
      ]} />}
    >
      <GuestConnectionsByDayChart 
        data={guestConnectionsChartData} 
        startTimeMilliseconds={selectedDateRange.from.getTime()} 
        endTimeMilliseconds={selectedDateRange.to.getTime()} 
      />
    </ChartCard>
    <ChartCard 
      text="Guest connections by time of day" 
      tooltip="Breakdown of guest connections over time of day."
      legend={<NewReturningLegend payload={[
        { value: 'new', color: "var(--grass-9)" },
        { value: 'returning', color: "var(--amber-9)" }
      ]} />}
    >
      <GuestConnectionsByTimeOfDayChart 
        data={guestConnectionsChartData} 
        startTimeMilliseconds={selectedDateRange.from.getTime()} 
        endTimeMilliseconds={selectedDateRange.to.getTime()} 
      />
    </ChartCard>
    <ChartCard 
      text="Guest connections by day of the week" 
      tooltip="Breakdown of guest connections over day of week."
      legend={<NewReturningLegend payload={[
        { value: 'new', color: "var(--grass-9)" },
        { value: 'returning', color: "var(--amber-9)" }
      ]} />}
    >
      <GuestConnectionsByDayOfWeekChart 
        data={guestConnectionsChartData} 
        startTimeMilliseconds={selectedDateRange.from.getTime()} 
        endTimeMilliseconds={selectedDateRange.to.getTime()} 
      />
    </ChartCard>
  </React.Fragment>
)}

{selectedDateRangeInDays >= 62 && (
  <React.Fragment>
        <ChartCard 
      text="Guest connections by month" 
      tooltip="Breakdown of guest connections by month."
      legend={<NewReturningLegend payload={[
        { value: 'new', color: "var(--grass-9)" },
        { value: 'returning', color: "var(--amber-9)" }
      ]} />}
    >
      <GuestConnectionsByMonthChart 
        data={guestConnectionsChartData} 
        startTimeMilliseconds={selectedDateRange.from.getTime()} 
        endTimeMilliseconds={selectedDateRange.to.getTime()} 
      />
    </ChartCard>
    <ChartCard 
      text="Guest connections by day" 
      tooltip="Breakdown of guest connections by day."
      legend={<NewReturningLegend payload={[
        { value: 'new', color: "var(--grass-9)" },
        { value: 'returning', color: "var(--amber-9)" }
      ]} />}
    >
      <GuestConnectionsByDayChart 
        data={guestConnectionsChartData} 
        startTimeMilliseconds={selectedDateRange.from.getTime()} 
        endTimeMilliseconds={selectedDateRange.to.getTime()} 
      />
    </ChartCard>
    <ChartCard 
      text="Guest connections by time of day" 
      tooltip="Breakdown of guest connections over time of day."
      legend={<NewReturningLegend payload={[
        { value: 'new', color: "var(--grass-9)" },
        { value: 'returning', color: "var(--amber-9)" }
      ]} />}
    >
      <GuestConnectionsByTimeOfDayChart 
        data={guestConnectionsChartData} 
        startTimeMilliseconds={selectedDateRange.from.getTime()} 
        endTimeMilliseconds={selectedDateRange.to.getTime()} 
      />
    </ChartCard>
    <ChartCard 
      text="Guest connections by day of the week" 
      tooltip="Breakdown of guest connections over day of week."
      legend={<NewReturningLegend payload={[
        { value: 'new', color: "var(--grass-9)" },
        { value: 'returning', color: "var(--amber-9)" }
      ]} />}
    >
      <GuestConnectionsByDayOfWeekChart 
        data={guestConnectionsChartData} 
        startTimeMilliseconds={selectedDateRange.from.getTime()} 
        endTimeMilliseconds={selectedDateRange.to.getTime()} 
      />
    </ChartCard>
  </React.Fragment>
)}
  
            {/* {selectedDateRangeInDays > 1 && selectedDateRangeInDays < 62 && (
    <ChartCard 
      text="Guest Connections" 
      tooltip="Breakdown of guest connections over time."
      legend={<NewReturningLegend payload={[
        { value: 'new', color: "var(--grass-9)" },
        { value: 'returning', color: "var(--amber-9)" }
      ]} />}
    >
      <GuestConnectionsChart 
        data={guestConnectionsChartData} 
        startTimeMilliseconds={selectedDateRange.from.getTime()} 
        endTimeMilliseconds={selectedDateRange.to.getTime()} 
      />
    </ChartCard>
  )} */}
            <Flex
              display="flex"
              height="180px"
              width='100%'
              maxWidth="1000px"
              gap="5"
              direction="row"
              justify="center"
            >
              {metricTileDataTwo.map((entry, index) => (
                <MetricCard key={index}
                  metricName={entry.name}
                  metricIcon={entry.icon}
                  metric={entry.metric}
                  updates={entry.updates}
                  tooltip={entry.tooltip}
                />
              ))}
            </Flex>
              {initialItems.length > 1 && locationSelection.length > 1 ? (
                <>
                  <ChartCard text="Guest connections by location" 
                    tooltip="Breakdown of guest connections over time, by location."
                    legend={<NewReturningLegend payload={[
                      { value: 'new', color: "var(--grass-9)" },
                      { value: 'returning', color: "var(--amber-9)" }
                    ]} />}
                  >
                    <GuestConnectionsByLocationsChart data={guestConnectionsByLocationsChartData}/>
                  </ChartCard>
                </>
            ): null} 
            {/* <ChartCard text="Guest Connections by Location" tooltip="Trends of guest connections by location.">
              <LineChart />
            </ChartCard>
            <ChartCard text="Guest Connections Heatmap" tooltip="Guest connection density comparison over periods.">
              <HeatmapChart />
            </ChartCard> */} 
              <Flex
                    display="flex"
                    direction="row"
                    align="start"
                    gap="5"
                    width="100%"
                    maxWidth="1000px"
                  >
                  <ChartCard text="Unique guests by age" 
                    tooltip="Breakdown of unique guests by age."
                  >
                    <UniqueGuestsByAgeChart metricsData={metricsData}/>
                  </ChartCard>
                  <ChartCard text="Unique guests by gender" 
                    tooltip="Breakdown of unique guests by gender."
                  >
                    <UniqueGuestsByGenderChart metricsData={metricsData}/>
                  </ChartCard>
                  </Flex>
          </Flex>
        </React.Fragment>
      )}
      </div>
    </React.Fragment>
  );
}
