// hooks/useDateRange.js
import { useState } from 'react';
import { subDays, startOfDay, endOfDay } from 'date-fns';

export const useDateRange = () => {
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: startOfDay(subDays(new Date(), 7)),
    to: endOfDay(new Date()),
  });

  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };

  return {
    selectedDateRange,
    handleDateRangeChange,
  };
};