import { useMutation } from "@tanstack/react-query";
import { attemptDeleteInviteUser } from '../../api/UsersApi';
import { toast } from 'react-toastify'; 

export const useDeleteInviteUser = () => {
  return useMutation({
    mutationKey: 'inviteUser',
    mutationFn: async (data) => attemptDeleteInviteUser(data),
    onMutate: async (data) => {
    },
    onSuccess: (data) => {
     toast.success("Invite cancelled", {
      icon: false
     })
      
    },
    onError: (error) => {
      toast.error("Request failed", {
        icon: false
       })
    },
    onSettled: async(_, error) => {
      
    }
  });
};
