import { useQuery } from '@tanstack/react-query';
import { attemptCheckInvitation } from '../../api/UsersApi';

const fetchCheckInvitation = async ({ queryKey }) => {
    const [, { token, email }] = queryKey;
    return attemptCheckInvitation(token, email).then((res) => res.data);
};

export const useCheckInvitation = (token, email) => {
    return useQuery({
        queryKey: ['checkInvitation', { token, email }],
        queryFn: fetchCheckInvitation,
        enabled: !!token && !!email, // Only run the query if token and email are provided
    });
};