import * as React from "react";
import {
  CheckIcon,
  WandSparkles,
} from "lucide-react";

import { Cn } from "components/utils/Cn";
import { Separator } from "components/separator/Separator";
import { ShadButton } from "components/buttons/ShadButton";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/popover/Popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/command/Command";
import { Text, Flex, Button } from "@radix-ui/themes";
import { CaretSortIcon, Cross1Icon } from "@radix-ui/react-icons";

const LocationMultiSelect = React.forwardRef(
  (
    {
      toggleFlexGrow,
      noShowBorder,
      options,
      onValueChange,
      defaultValue = [],
      placeholder = "Select Locations",
      animation = 0,
      maxCount = 0,
      modalPopover = false,
      asChild = false,
      className,
      ...props
    },
    ref
  ) => {
    // All these states could probably be refactored to take an object state later!
    const [selectedValues, setSelectedValues] = React.useState(defaultValue);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [isAnimating, setIsAnimating] = React.useState(false);
    const [dateDisplay, setDateDisplay] = React.useState("All Locations");
    const [checkBoxBool, setCheckBoxBool] = React.useState([]);
    const [topCheckBoxBool, setTopCheckBoxBool] = React.useState(true);


    // Initialize checkBoxBool once options are loaded or change
    React.useEffect(() => {
      if (options && options.length > 0) {
        setCheckBoxBool(new Array(options.length).fill(true));
      }
    }, [options]);
    
    const handleInputKeyDown = (event) => {
      if (event.key === "Enter") {
        setIsPopoverOpen(true);
      } else if (event.key === "Backspace" && !event.currentTarget.value) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
        setDateDisplay(newSelectedValues.length === 0 ? "Select Locations" : (
          newSelectedValues.length === options.length ? "All Locations" : 
          `${newSelectedValues.length} Location`
        ))
        setCheckBoxBool(options.map((option) => newSelectedValues.includes(option.value)));
        setTopCheckBoxBool(newSelectedValues.length === options.length ? true : false)
      };
    };

    const toggleOption = (option) => {
      const newSelectedValues = selectedValues.includes(option)
        ? selectedValues.filter((value) => value !== option)
        : [...selectedValues, option];

      if (maxCount > 0 && newSelectedValues.length > maxCount) {
        clearExtraOptions();

      } else {
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
        setDateDisplay(newSelectedValues.length === 0 ? "Select Locations" : (
          newSelectedValues.length === options.length ? "All Locations" : 
          `${newSelectedValues.length} Location`
        ))
        setCheckBoxBool(options.map((option) => newSelectedValues.includes(option.value)));
        setTopCheckBoxBool(newSelectedValues.length === options.length ? true : false)
      }
    };

    const handleClear = () => {
      setSelectedValues([]);
      onValueChange([]);
      setDateDisplay("Select Locations");
      setCheckBoxBool(new Array(options.length).fill(false));
      setTopCheckBoxBool(false);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const clearExtraOptions = () => {
      const newSelectedValues = selectedValues.slice(0, maxCount);
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
      setDateDisplay(newSelectedValues.length === 0 ? "Select Locations" : (
        newSelectedValues.length === options.length ? "All Locations" : 
        `${newSelectedValues.length} Location`
      ))
      setCheckBoxBool(options.map((option) => newSelectedValues.includes(option.value)));
      setTopCheckBoxBool(newSelectedValues.length === options.length ? true : false)
    };

    const toggleAll = () => {
      if (selectedValues.length === options.length) {
        handleClear();
      } else {
        const allValues = options.map((option) => option.value);
        setSelectedValues(allValues);
        onValueChange(allValues);
        setDateDisplay("All Locations");
        setCheckBoxBool(new Array(options.length).fill(true));
        setTopCheckBoxBool(true);
      }
    };

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        modal={modalPopover}
      >
        <PopoverTrigger asChild>
          <ShadButton
            ref={ref}
            {...props}
            onClick={handleTogglePopover}
            className={className}
            style={{
              display: "flex",
              width: "100%",
              height: "var(--space-6)",
              padding: "0px var(--space-3)",
              alignItems: "center",
              gap: "var(--space-2)",
              borderRadius: "var(--radius-2)",
              border: noShowBorder ? null : "1px solid var(--gray-a7)",
              backgroundColor: "var(--color-solid)",
            }}
          >
            <Text color="gray" weight="medium">
              {dateDisplay}   
            </Text>
            {toggleFlexGrow ? <Flex flexGrow="1" /> : null}
            <CaretSortIcon style={{color: "var(--gray-11)"}}/>
          </ShadButton>
        </PopoverTrigger>

        <PopoverContent
          className="w-auto p-0"
          align="start"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          <Command>
            <Flex className="search-row" style={{alignSelf: "stretch"}} display="flex" pt="2" pb="2" pr="2" pl="4" justify="center" align="center" gap="2">
              <div
                key="all"
                onClick={toggleAll}
                style={{
                  cursor: "pointer", 
                  marginLeft: "15px", 
                  marginRight: "8px",
                  border: "1px solid var(--gray-a11)"
                }}
                className={Cn(
                  "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-secondary",
                  topCheckBoxBool
                    ? "bg-primary border-primary text-primary-foreground"
                    : "opacity-50 [&_svg]:invisible"
                )}
              >
                <CheckIcon className="h-4 w-4" />
              </div>
              <CommandInput 
                style={{
                  display: "flex",
                  width: "215px",
                  height: "32px",
                  alignItems: "flex-start",
                  gap: "var(--space-2)",
                  margin: "8px 8px 8px 8px"
                }}
                placeholder={"Search..."}
                onKeyDown={handleInputKeyDown}
              />
              <Button style={{cursor: "pointer", marginRight: "8px"}} onClick={handleClear}>
                <Cross1Icon className="h-4 w-4" />
              </Button>
            </Flex>
            <Separator size="4" />
            <CommandList>
              <CommandEmpty>No Locations Found</CommandEmpty>
              <CommandGroup>  
                {options.map((option, index) => {
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => toggleOption(option.value)}
                      className="cursor-pointer"
                    >
                      <div
                        style={{border: "1px solid var(--gray-a11)"}}
                        className={Cn(
                          "mr-3 flex h-4 w-4 items-center justify-center rounded-sm border border-secondary",
                          checkBoxBool[index] === true
                            ? "bg-primary border-primary text-primary-foreground"
                            : "opacity-50 [&_svg]:invisible"
                        )}
                      >
                        <CheckIcon className="h-4 w-4" />
                      </div>
                      <Text color="gray" as="span" size="2" weight="regular">{option.label}</Text>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
        {animation > 0 && selectedValues.length > 0 && (
          <WandSparkles
            className={Cn(
              "cursor-pointer my-2 text-foreground bg-background w-3 h-3",
              isAnimating ? "" : "text-muted-foreground"
            )}
            onClick={() => setIsAnimating(!isAnimating)}
          />
        )}
      </Popover>
    );
  }
);

LocationMultiSelect.displayName = "LocationMultiSelect";

export default LocationMultiSelect ;
