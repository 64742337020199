import { Text, Card, Flex, Tooltip, IconButton } from "@radix-ui/themes";
import { InfoCircledIcon } from "@radix-ui/react-icons";

export function MetricCard({ metricName, metricIcon, metric, subMetric, updates, tooltip }) {

  const getColor = (text) => {
    if (text === '+0%') {
      return 'inherit'; // No color change for +0%
    }
    return text.includes('-') ? 'var(--red-9)' : 'var(--grass-9)';
  };

  const getStyledUpdates = (updates) => {
    const match = updates.match(/([+-]?\d+%)/);
    if (match) {
      const [number] = match;
      const rest = updates.replace(number, '');
      return (
        <>
          <span style={{ color: getColor(number) }}>{number}</span>
          {rest}
        </>
      );
    }
    return updates;
  };

  return (
    <Card style=
      {
        { 
          display: "flex",
          padding: 'var(--space-4)',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flex: '1 0 0',
          alignSelf: 'stretch',
          height: '100%'
        }
      }
    >
      <Flex 
        display="flex" 
        direction="column" 
        align="start" 
        gap="4"
        style={{alignSelf: "stretch"}} 
      >
        <Flex
          display="flex"
          align="center"
          gap="2"
          style={{alignSelf: "stretch"}}
        >
          {metricIcon}
          <Text gap="2" size="1" weight="medium" style={{color: 'var(--gray-11)', display: 'flex', alignItems: 'center'}}>{metricName}</Text>
        </Flex>
        <Flex align="center" gap="2">
          <Text size="6" weight="bold">{metric}</Text>
          <Text size="2" weight="regular" style={{color: 'var(--gray-11)'}}>{subMetric}</Text>
        </Flex>
      </Flex>
      <Flex justify="between" align="center" style={{alignSelf: 'stretch'}}>
        <Tooltip content="Last period refers to the previous equivalent time range.">
          <Text size="1" weight="regular" style={{color: 'var(--gray-11)'}}>{getStyledUpdates(updates)}</Text>
        </Tooltip>
        <Tooltip content={tooltip}>
          <IconButton size="1" variant="ghost" color="gray">
            <InfoCircledIcon />
          </IconButton>
        </Tooltip>
      </Flex>
    </Card>
  )
}