// hooks/useLiveConnectionData.js
import { useState, useEffect } from 'react';
import { useGetLiveConnectionStats } from '../app/useGetLiveConnectionStats';

export const useLiveConnectionStats = (locationSelection, locationsData) => {
  const [accessPointMacList, setAccessPointMacList] = useState([]);
  const [liveConnectionPayload, setLiveConnectionPayload] = useState(null);
  const [liveConnectionStat, setLiveConnectionStat] = useState([]);
  
  useEffect(() => {
    if (locationSelection.length > 0) {
      const selectedLocations = locationsData?.filter(location => locationSelection.includes(location.id));

      const access_point_mac_list = [
        ...new Set(selectedLocations?.flatMap(location => location.access_point_mac_list)),
      ];

      setAccessPointMacList(access_point_mac_list);

      // Prepare payload for fetching live connection stats
      setLiveConnectionPayload({
        timestamp: Date.now(),
        request_type: "live-connections-metrics",
        payload: {
          access_point_mac_list,
        },
      });
    }
  }, [locationSelection, locationsData]);

  const { data: liveConnectionStats } = useGetLiveConnectionStats(liveConnectionPayload);

  useEffect(() => {
    if (liveConnectionStats) {
      const liveData = liveConnectionStats.data.data;
      setLiveConnectionStat([
        {
          title: "Connections",
          value: liveData.live_connections,
          subValue: null,
          badge: false,
        },
        {
          title: "New Connections",
          value: liveData.new_connections,
          subValue: liveData.new_connection_percent,
          badge: false,
        },
        {
          title: "Returning Connections",
          value: liveData.returning_connections,
          subValue: liveData.returning_connection_percent,
          badge: false,
          separator: true,
        },
        {
          title: "Wi-Fi Access Points",
          value: liveData.offline_access_points_count,
          subValue: liveData.online_access_points_count,
          badge: true,
        },
      ]);
    }
  }, [liveConnectionStats]);

  // Update live stats every 30 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLiveConnectionPayload(prevPayload => ({
        ...prevPayload,
        timestamp: Date.now(),
      }));
    }, 30000);

    return () => clearInterval(intervalId);
  }, [accessPointMacList]);

  return {
    liveConnectionStat,
  };
};