import React from 'react'

export function Header({children, type}) {
  let header_element;

  if (type === "organisation-main-header") {
    header_element = (
      <header style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        alignSelf: "stretch",
      }}>
        {children}
      </header>
    )
  } else if (type === "organisation-sub-header") {
    header_element = (
      <header style={{
        display: "flex",
        padding: "0px var(--space-4)",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
      }}>
        {children}
      </header>
    )
  } else {
    header_element = (
      <header style={{
        display: "flex",
        padding: "var(--space-4) var(--space-5)",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
      }}>
        {children}
      </header>
    )
  }
  return header_element
}