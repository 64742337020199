/**
 * Logo Component
 * @description Renders the branding logo with customizable colors based on props.
 * @param {Object} props - The properties object.
 * @param {string} props.width - The width of the icon.
 * @param {string} props.height - The height of the icon.
 * @param {string} props.textSize - The textsize of the icon.
 * @param {boolean} props.primary - If true, use primary color scheme.
 * @param {boolean} props.secondary - If true, use secondary color scheme.
 * @param {boolean} props.default - If true, use default color scheme.
 * @param {string} props.icon - The name of the icon to display.
 * @returns {JSX.Element} The rendered logo component.
 */

import React from 'react'
import { Icon } from 'components/tokens/Icon'
import { Flex, Text } from '@radix-ui/themes';

export function Logo({ icon, color, width, height }) {
    return (
        <Flex gap="4">            
            <Icon name={icon} color={color} width={width} height={height}/>            
            <Flex gap="2">
                <Text size="6" weight="bold" style={{color: '#FFFFFF'}}>b</Text>
                <Text size="6" weight="bold" style={{color: '#FFFFFF'}}>connect</Text>
            </Flex>
        </Flex>
    )
}
