import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { DateTime } from "luxon";

dayjs.extend(duration);

export function granularity(startTimeMilliseconds, endTimeMilliseconds, timezone) {
  const startDate = dayjs(startTimeMilliseconds);
  const endDate = dayjs(endTimeMilliseconds);

  // The one is added here because the function differenceInUnitTime has a "floor function" effect on the number of days!
  // Because of the ShadCN DatePickerWithRange component, selecting for example 6th October to 7th October would give a
  // a time interval of 1 day 23 hours 59 minutes 59 seconds instead of 2 days! 
  const differenceInUnitTime = (unitOfTime) => endDate.diff(startDate, unitOfTime) + 1;

  const granularityRules = {
    '1-2': [1, "hour"],
    '2-32': [1, "day"],
    '32-56': [1, "week"],
    '56-730': [1, "month"],
    '730-730': [1, "year"]
  };

  let numberOfBins = 0;
  let intervalLength = [];

  const totalDays = differenceInUnitTime("day");

  // Loop through granularity rules to find the correct intervalLength
  for (const [key, value] of Object.entries(granularityRules)) {
    const [lower, upper] = key.split('-').map(Number);

    if (lower <= totalDays && totalDays < upper) {
      intervalLength = value;
      const timeIntervalAdjusted = differenceInUnitTime(value[1]);
      numberOfBins = Math.floor(timeIntervalAdjusted / value[0]) ;
      break; // Exit the loop when a match is found
    }
  }  

  // Handle case for totalDays >= 365
  if (totalDays >= 730) {
    intervalLength = granularityRules['730-730'];
    const timeIntervalAdjusted = differenceInUnitTime(intervalLength[1]);
    numberOfBins = Math.floor(timeIntervalAdjusted / intervalLength[0]);
  }
  // Fallback for edge cases where no rule matches
  if (!intervalLength) {
    throw new Error("No matching granularity rule found for the given date range.");
  }

  // Calculate microsecond interval and generate bins
  const millisecondInterval = dayjs.duration(intervalLength[0], intervalLength[1]).asMilliseconds();

  const bins = Array.from({ length: numberOfBins }, (_, i) => startTimeMilliseconds + (i * millisecondInterval));

  // Add end time if not included in the last bin, and we determine that we are not using an hour granularity filter 
  // and are not within the DST period.
  if (bins.length > 0 && endTimeMilliseconds > bins[bins.length - 1] ) {
    if (intervalLength[1] === "hour") {
      if (DateTime.fromMillis(endTimeMilliseconds, { zone: timezone }).isInDST) {
        bins.push(endTimeMilliseconds);
      }
    } else {
      bins.push(endTimeMilliseconds);
    }
  }

  // This snippet helps us find the daylight savings time.
  const startTimeYear = DateTime.fromMillis(startTimeMilliseconds, { zone: timezone }).year;
  let dst_end_date = DateTime.fromObject({ year: startTimeYear, month: 10, day: 20 }, { zone: timezone });

  // Loop through the days of October to find when DST ends
  while (dst_end_date.isInDST) {
    dst_end_date = dst_end_date.plus({ days: 1 });
  }

  // Finally, take away the date interval of places that do
  if (DateTime.fromMillis(endTimeMilliseconds, { zone: timezone }) > dst_end_date && intervalLength[1] === "hour") {
    bins.unshift(startTimeMilliseconds - millisecondInterval);
  }


  // Create bin intervals
  const binIntervalArray = [];
  for (let i = 0; i < bins.length - 1; i++) {
    binIntervalArray.push([bins[i], bins[i + 1]]);
  }
  return [binIntervalArray, intervalLength[1]];
}


