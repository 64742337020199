import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, TextField, Box, Button, Separator } from "@radix-ui/themes";
import { Header } from "components";
import { ReactComponent as BCononnectLogo } from '../../assets/icons/logo.svg';
import { getProfile } from "api/UsersApi";
import { useUpdateUser } from 'hooks/app/useUpdateUser';
import { useSetPassword } from 'hooks/auth/useSetPassword';
import { ThemeProvider, useTheme } from "../../context/ThemeContext"; // Import ThemeProvider and useTheme
import { Icon } from 'components/tokens/Icon'

function FirstTimeLoginComponent() {
  const navigate = useNavigate();
  const { appearance } = useTheme();
  const updateUserMutation = useUpdateUser();
  const passwordResetMutation = useSetPassword();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [organisationName, setorganisationName] = useState('');
  const [organisationImageUrl, setOrganisationImageUrl] = useState('');
  const [userId, setUserId] = useState(null);
  
  const [errors, setErrors] = useState({});
  
  
  const iconColor = appearance === 'dark' ? 'white' : 'blue';

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await getProfile();

        if (!profile.data.is_first_login || profile.data.user_role !== 'Owner') {
          navigate('/dashboard');
          return;
        }

        setFormData({
          firstName: profile.data.first_name,
          lastName: profile.data.last_name,
          email: profile.data.email,
          newPassword: '',
          confirmPassword: '',
        });
        setorganisationName(profile.data.organisation_name);
        setOrganisationImageUrl(`${profile.data.organisation_image_url}`);
        setUserId(profile.data.id);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
    
  
    fetchProfile();
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!formData.newPassword) {
      newErrors.newPassword = 'Password is required';
    } else if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(formData.newPassword)) {
      newErrors.newPassword = 'Password does not meet requirements';
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Please confirm password';
    } else if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const updateData = { id: userId, is_first_login: false };
      if (formData.firstName.trim()) updateData.first_name = formData.firstName.trim();
      if (formData.lastName.trim()) updateData.last_name = formData.lastName.trim();
  
      if (Object.keys(updateData).length > 1) {
        updateUserMutation.mutate(updateData, {
          onSuccess: () => {
            if (formData.newPassword && formData.confirmPassword) {
              passwordResetMutation.mutate({ user_id: userId, new_password: formData.newPassword }, {
                onSuccess: () => {
                  navigate('/dashboard'); // Navigate to the dashboard upon successful form submission
                },
                onError: (error) => {
                  console.error('Password reset failed:', error);
                }
              });
            } else {
              navigate('/dashboard'); // Navigate to the dashboard if no password reset is needed
            }
          },
          onError: (error) => {
            console.error('Update failed:', error);
          }
        });
      } else if (formData.newPassword && formData.confirmPassword) {
        passwordResetMutation.mutate({ user_id: userId, new_password: formData.newPassword }, {
          onSuccess: () => {
            navigate('/dashboard'); // Navigate to the dashboard upon successful form submission
          },
          onError: (error) => {
            console.error('Password reset failed:', error);
          }
        });
      }
    }
  };

  const handleChange = (field) => (e) => {
    setFormData(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  return (
    <React.Fragment>      
      <div >
        <div style={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "var(--color-panel-solid)" }}>
        <Header>
          <Flex display="flex" align="center" gap="4">
            <Icon name='LogoBWifi' color={iconColor} width='20px' height='16px'/>
            <Text className="" size="4" weight="bold">
              b connect
            </Text>
          </Flex>
          <Flex display="flex" align="center" gap="4">
            <div>
              <Button variant="surface" radius='large' color='gray' size="2" onClick={() => {
                  const supportEmail = 'support@b-connect.co.uk';
                  const subject = 'Support Request';
                  const body = 'Hello, I need help with...';
                  const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                  window.location.href = mailtoLink;
              }}>
                <Text size="2" variant="surface">
                  Support
                </Text>
              </Button>
            </div>
          </Flex>
        </Header>
        <Separator size="4" orientation="horizontal" />
        </div>
        <Flex justify='center' gap="4" display="flex">
          <div 
          style={{
            border: '1px solid var(--gray-a6)',
            borderRadius: '8px',
            width: '528px',
            marginTop: '2rem',
            justifyContent: 'flex-start',
            overflow: 'hidden' 
          }}
          >
            
            <img src={organisationImageUrl} alt='Organisation Image' 
              style=
                {{ width: '528px', 
                  height: '200px', 
                  borderTopLeftRadius: '8px',
                   borderTopRightRadius: '8px' 
                  }} />
          <Separator size="4" orientation="horizontal" />
          <Flex direction="column" gap="4" align="start" p="4">
            

            <Text size="4" weight="bold" >
              Sign Up with {organisationName}
            </Text>

            <Text size="1" color="gray">
              Welcome to b connect, we hope you enjoy using our platform. If you have any questions or feedback, please contact <span style={{
                textDecoration: 'underline'
              }}>support</span>.
            </Text>

          </Flex>
          
          <div className='p-4 bg-colors-bg-natural-surface rounded-b-lg' style={{ paddingTop: '0', paddingBottom: '0' }}>
          <form onSubmit={handleSubmit}>
            <Flex direction="column" gap="4">
              <Flex direction="row" gap="4" justifyContent="space-between">
                <Box width="48%">
                  <Text as="label" size="2" weight="medium">First Name</Text>
                  <TextField.Root
                    value={formData.firstName}
                    onChange={handleChange('firstName')}
                    placeholder="First Name"
                    mt="2"
                  />
                  {errors.firstName && (
                    <Text color="red" size="1">Input your first name</Text>
                  )}
                </Box>

                <Box width="49%">
                  <Text as="label" size="2" weight="medium">Last Name</Text>
                  <TextField.Root
                    value={formData.lastName}
                    onChange={handleChange('lastName')}
                    placeholder="Last Name"
                    mt="2"  
                  />
                  {errors.lastName && (
                    <Text color="red" size="1">Input your last name</Text>
                  )}
                </Box>
              </Flex>
              <Flex direction="column" gap="4">
              <Box>
                <Text as="label" size="2" weight="medium">Email Address</Text>
                <TextField.Root
                  value={formData.email}
                  disabled
                  mt="2" 
                />
                <Text size="1" color="gray">
                  You can change this later.
                </Text>
              </Box>
              </Flex>

              <Flex direction="row" gap="4" justifyContent="space-between">
                <Box width="48%">
                  <Text as="label" size="2" mb="2" weight="medium">New Password</Text>
                  <TextField.Root
                    type='password'
                    value={formData.newPassword}
                    onChange={handleChange('newPassword')}
                    mt="2"
                    mb="6px" 
                  />
                  <Text size="1" color={errors.newPassword ? "red" : "gray"} >
                    <p>
                    Input a new password. Min. 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.
                    </p>
                  </Text>
                </Box>
                <Box width="49%">
                  <Text as="label" size="2" mb="2" weight="medium">Confirm New Password</Text>
                  <TextField.Root
                    type='password'
                    value={formData.confirmPassword}
                    onChange={handleChange('confirmPassword')}
                    mt="2" 
                  />
                  {errors.confirmPassword && (
                    <Text color="red" size="1">Confirm your new password.</Text>
                  )}
                </Box>
              </Flex>
            <Flex direction="column" alignItems="center" justifyContent="center" style={{ paddingBottom: '16px' }}>
              <Button 
                type="submit" 
                size="3"
                color="blue"
              >
                Launch b connect
              </Button>
              </Flex>
            </Flex>
          </form>
        </div>
      </div>
      </Flex>
    </div>
    </React.Fragment>
    
  );
}

export default function FirstTimeLogin() {
  return (
    <ThemeProvider>
      <FirstTimeLoginComponent />
    </ThemeProvider>
  );
}