import RouteManager from "RouteManager";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider, useTheme } from "./context/ThemeContext";  // Import the ThemeContext
import { isMobile } from 'react-device-detect';
import "react-toastify/dist/ReactToastify.css";
import '@radix-ui/themes/styles.css';
import { Theme, Flex, Text } from "@radix-ui/themes";
import './index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 1000,
    },
  },
});

function ThemedApp() {
  const { appearance } = useTheme();  // Access the theme context

  return (
    <Theme
      appearance={appearance}  // Use theme from context
      accentColor="blue"
      grayColor="gray"
      panelBackground="solid"
      scaling="100%"
      style={{
        display: 'flex', 
        flexDirection: 'column', 
        height: '100vh', 
        alignSelf: 'stretch',
        flex: '1 0 0',
      }}
    >
      <RouteManager />
      <ReactQueryDevtools initialIsOpen={false} />
    </Theme>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ThemedApp />  {/* Render the app within the ThemeProvider */}
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
