import { useQueryClient, useMutation } from "@tanstack/react-query";
import { attemptUpdateUser } from "../../api/UsersApi";
import { toast } from 'react-toastify';

const useSyncMutation = (mutationFn, options) => {
  const mutationResults = useMutation(mutationFn, options);

  return {
    ...mutationResults,
    mutate: (...params) => {
      if (!mutationResults.isLoading) {
        mutationResults.mutate(...params);
      }
    }
  };
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient()

  return useSyncMutation({
    mutationKey: ["update-user"],
    mutationFn: async (data) => attemptUpdateUser(data),
    onError: (error) => {
      toast.error("Profile Updated Failed", {
        icon: false
       })
    },
    onSettled: async (_, error) => {
      if (error) {
        //@TODO: Handle error
      } else {
    
        await queryClient.invalidateQueries({ queryKey: ['profile'] });
      }
    }
  });
};