import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptDeleteOrgUser } from '../../api/OrgApi';
import { toast } from 'react-toastify';

export const useDeleteOrgUser = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationKey: 'delete-org-user',
    mutationFn: ({ owner_id, data }) => attemptDeleteOrgUser(owner_id, data),
    onMutate: async (data) => {
      // Optional: Implement optimistic update logic here
    },
    onSuccess: () => {
      toast.success("User Deleted", {
        icon: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["org-users"],
      });
    },
    onError: (error) => {
      toast.error("Delete Failed", {
        icon: false,
      });
    }
  });
};