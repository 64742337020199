import React, { useEffect, useState } from 'react'
import { Flex, Text } from '@radix-ui/themes';
import * as Toast from "@radix-ui/react-toast";
import './toastStyle.css';

export function ToastAlert({ toastMessage }) {
  const [open, setOpen] = useState(false);
  const theme = localStorage.getItem('theme');
  useEffect(() => {
    if (toastMessage) {
      setOpen(true);
    }
  }, [toastMessage]);

  return (
    <Toast.Provider swipeDirection='right' duration='5000'>
      <Toast.Root className='ToastRoot' open={open} onOpenChange={setOpen}
        style={{
          backgroundColor: theme === 'dark' ? 'var(--color-panel-solid)' : 'var(--gray-a12)',
          justifyContent: 'center',
          borderRadius: 'var(--radius-2)',
        }}>
        <Toast.Description className='ToastDescription' asChild
        style={{
          color: 'var(--gray-contrast)',
        }}>
            <Flex px="3" py="2">
              <Text size="2" weight="medium">{toastMessage}</Text>
            </Flex>
        </Toast.Description>
      </Toast.Root>
      <Toast.Viewport className='ToastViewport'/>
    </Toast.Provider>
  );
}
