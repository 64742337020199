import React, { createContext, useState, useEffect, useContext } from "react";


const initialAppearance = () => {
  const storedTheme = localStorage.getItem("theme");
  return storedTheme === null || storedTheme === '' || storedTheme === 'null' ? "system" : storedTheme;
};

// Create the context
const ThemeContext = createContext();

// Custom hook to use the theme context
export function useTheme() {
  return useContext(ThemeContext);
}

// Function to detect current system theme (light or dark)
function getSystemTheme() {
  return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
}

// ThemeProvider component to wrap the app
export function ThemeProvider({ children }) {
  const [appearance, setAppearance] = useState(initialAppearance);  // Default theme is light

  // Apply system theme only if "system" is selected
  useEffect(() => {
    if (appearance === "system") {
      const applySystemTheme = () => {
        setAppearance(getSystemTheme());
      };

      // Set the initial system theme
      applySystemTheme();

      // Listen for changes in system theme
      const systemThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      systemThemeMq.addEventListener("change", applySystemTheme);

      localStorage.setItem("theme", appearance);

      // Clean up the listener
      return () => {
        systemThemeMq.removeEventListener("change", applySystemTheme);
      };
    }
  }, [appearance]);

  const value = {
    appearance,
    setAppearance,
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}
