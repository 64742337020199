import React, { useState, useEffect, useTransition } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex, Text, TextField, Box, Button, Separator } from "@radix-ui/themes";
import { Header } from "components";
import { useRegistration, useCheckInvitation } from "hooks";
import { ThemeProvider, useTheme } from "../../context/ThemeContext"; // Import ThemeProvider and useTheme
import { Icon } from 'components/tokens/Icon';
import useForm from "../../hooks/validation/useForm";
import {
  validateEmail,
  validatePassword,
} from "../../components/utils/validationUtils";

const initialFormState = {
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  confirmPassword: "",
  token: ""
};

export default function Registration() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { appearance } = useTheme();
  const [organisationName, setOrganisationName] = useState('');
  const [organisationImageUrl, setOrganisationImageUrl] = useState('');
  const [isPending, startTransition] = useTransition();
  const [signup, setSignup] = useState(false);
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  // Use the custom hook to check the invitation
  const { data, isLoading, error } = useCheckInvitation(token, email);

  const initialState = {
    ...initialFormState,
    email: email || "",
    token: token || ""
  };

  const { mutate: registerUser, isError: isErrorRegistration, isSuccess: isSuccessRegistration} =  useRegistration()

  const validations = {
    firstName: (value) => (!value ? "Input your first name" : ""),
    lastName: (value) => (!value ? "Input your last name" : ""),
    email: (value) =>
      !value
        ? "Input your email address. For example: john@email.com"
        : !validateEmail(value)
        ? "Invalid email address"
        : "",
    password: (value) =>
      !value
        ? "Input a password"
        : !validatePassword(value)
        ? "Password must be at least 8 characters long and include a mix of uppercase letters, lowercase letters, numbers, and special characters."
        : "",
    confirmPassword: (value, formState) =>
      !value
        ? "Input a confirm password"
        : value !== formState.password
        ? "Passwords do not match"
        : "",
  };

  const { formState, errors, handleChange, handleSubmit, handleBlur, resetForm } = useForm(
    initialState,
    validations
  );

  const handleSignupSubmit = async (formState) => {
    const signupData = { 
      "first_name": formState.firstName , 
      "last_name": formState.lastName, 
      "email": formState.email, 
      "password" : formState.password, 
      "token" : formState.token
     };

    registerUser(signupData)
    if( isErrorRegistration){
      resetForm();
    }
  
  };

  useEffect(() => {
    if (isSuccessRegistration) {
      setSignup(true);
      navigate("/signup-successful");
    }
    
  }, [isSuccessRegistration]);

  useEffect(() => {
    if (data) {
      setOrganisationName(data.organisation_name);
      setOrganisationImageUrl(data.organisation_image_url);
    }
  }, [data]);

  if (isLoading) {
    return <div></div>;
  }

  if (error || (data && data.expired)) {
    return (
      <React.Fragment>
        <Flex p="5" direction="column" gap="5" height="100%"
            style={{alignSelf: 'stretch', alignItems: 'center', flex: '1 0 0', justifyContent: 'center'}}>
            <Flex direction="column" gap="2" style={{alignItems: 'center'}}>
                <Text size="5" weight="medium">
                    Link Expired
                </Text>
                <Text size="2" weight="regular" color="gray" style={{width:'296px', textAlign:'center'}}>
                    Please check your email inbox for a new link or contact the owner of your organisation to resend an invitation to you.
                </Text>
            </Flex>
        </Flex>
      </React.Fragment>
    );
  }



  const iconColor = appearance === 'dark' ? 'white' : 'blue';

  return (
    <ThemeProvider>
      <React.Fragment>      
        <div>
          <div style={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "var(--color-panel-solid)" }}>
          <Header>
            <Flex display="flex" align="center" gap="4">
              <Icon name='LogoBWifi' color={iconColor} width='20px' height='16px'/>
              <Text className="" size="4" weight="bold">
                b connect
              </Text>
            </Flex>
            <Flex display="flex" align="center" gap="4">
              <div>
                <Button variant="surface" radius='large' color='gray' size="2" onClick={() => {
                    const supportEmail = 'support@b-connect.co.uk';
                    const subject = 'Support Request';
                    const body = 'Hello, I need help with...';
                    const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                    window.location.href = mailtoLink;
                }}>
                  <Text size="2" variant="surface">
                    Support
                  </Text>
                </Button>
              </div>
            </Flex>
          </Header>
          <Separator size="4" orientation="horizontal" />
          </div>
          <Flex justify='center' gap="4" display="flex">
            <div 
            style={{
              border: '1px solid var(--gray-a6)',
              borderRadius: '8px',
              width: '528px',
              marginTop: '2rem',
              justifyContent: 'flex-start',
              overflow: 'hidden' 
            }}
            >
              
              <img src={organisationImageUrl} alt='Organisation Image' 
                style=
                  {{ width: '528px', 
                    height: '200px', 
                    borderTopLeftRadius: '8px',
                     borderTopRightRadius: '8px' 
                    }} />
            <Separator size="4" orientation="horizontal" />
            <Flex direction="column" gap="4" align="start" p="4">
              

              <Text size="4" weight="bold" >
                Sign Up with {organisationName}
              </Text>

              <Text size="1" color="gray">
                Welcome to b connect, we hope you enjoy using our platform. If you have any questions or feedback, please contact <span style={{
                  textDecoration: 'underline'
                }}>support</span>.
              </Text>

            </Flex>
            
            <div className='p-4 bg-colors-bg-natural-surface rounded-b-lg' style={{ paddingTop: '0', paddingBottom: '0' }}>
            <form onSubmit={(e) => handleSubmit(e, handleSignupSubmit)}>
              <Flex direction="column" gap="4">
                <Flex direction="row" gap="4" justifyContent="space-between">
                  <Box width="48%">
                    <Text as="label" size="2" weight="medium">First Name</Text>
                    <TextField.Root
                      name="firstName"
                      value={formState.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                      mt="2"
                    />
                    {errors.firstName && (
                      <Text color="red" size="1">Input your first name</Text>
                    )}
                  </Box>

                  <Box width="49%">
                    <Text as="label" size="2" weight="medium">Last Name</Text>
                    <TextField.Root
                      name="lastName"
                      value={formState.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                      mt="2"  
                    />
                    {errors.lastName && (
                      <Text color="red" size="1">Input your last name</Text>
                    )}
                  </Box>
                </Flex>
                <Flex direction="column" gap="4">
                <Box>
                  <Text as="label" size="2" weight="medium">Email Address</Text>
                  <TextField.Root
                    name="email"
                    value={formState.email}
                    disabled
                    mt="2" 
                  />
                  <Text size="1" color="gray">
                    You can change this later.
                  </Text>
                </Box>
                </Flex>

                <Flex direction="row" gap="4" justifyContent="space-between">
                  <Box width="48%">
                    <Text as="label" size="2" mb="2" weight="medium">New Password</Text>
                    <TextField.Root
                      name="password"
                      type='password'
                      value={formState.password}
                      onChange={handleChange}
                      mt="2"
                      mb="6px" 
                    />
                    <Text size="1" color={errors.password ? "red" : "gray"} >
                      <p>
                      Input a new password. Min. 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.
                      </p>
                    </Text>
                  </Box>
                  <Box width="49%">
                    <Text as="label" size="2" mb="2" weight="medium">Confirm New Password</Text>
                    <TextField.Root
                      name="confirmPassword"
                      type='password'
                      value={formState.confirmPassword}
                      onChange={handleChange}
                      mt="2" 
                    />
                    {errors.confirmPassword && (
                      <Text color="red" size="1">Confirm your new password.</Text>
                    )}
                  </Box>
                </Flex>
              <Flex direction="column" alignItems="center" justifyContent="center" style={{ paddingBottom: '16px' }}>
                <Button 
                  type="submit" 
                  size="3"
                  color="blue"
                >
                  Launch b connect
                </Button>
                </Flex>
              </Flex>
            </form>
          </div>
        </div>
        </Flex>
      </div>
      </React.Fragment>
    </ThemeProvider>
  );
}