import * as React from "react";
import { Flex, Text } from "@radix-ui/themes";

export default function NotAvailableOnMobile() {
  return (
    <Flex className="card-container" display="flex" width="100vw" height="100vh" px="8" py="5" direction="column" justify="center" align="center" gap="5" flexShrink="0">
      <Flex className="frame-528172610" style={{alignSelf: "stretch"}} display="flex" direction="column" align="center" gap="2">
        <Text size="5" weight="medium">
          Not Available on Mobile
        </Text>
        <Text style={{color: "var(--gray-11)", textAlign: "center"}} size="2" weight="regular">
          Please open <Text style={{color: "var(--accent-11)"}}>app.b-connect.co.uk</Text> on a desktop or tablet device.
        </Text>
      </Flex>
    </Flex>
  )
}