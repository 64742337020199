import React, { useEffect, useState, useTransition, useCallback } from "react";
import { Button, Modal, SelectInput, TextInput, Icon, Card } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { BodySM, BodyXS, LabelMD, TitleLG } from "components/utils/Typography";
import {
  CreateLocation,
  EditLocation,
  SetUpEmail,
  DeleteLocation,
  DeleteCustomer,
} from "./Modals";
import {
  useFatchOrgType,
  useFatchOrgDetails,
  useCreateOrgLocation,
  useGetOrgLocations,
  useDeleteOrgLocation,
  useEditOrgLocation,
  useGetOrgUsers,
  useUpdateOrg,
} from "hooks";
import { status, ap_group } from "mock";
import _debounce from "lodash/debounce";
import { useDeleteOrg } from "hooks/app/useDeleteOrg";

export default function CreateCustomer() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [modalTitle, setModalTitle] = useState("");
  const [sendSetupEmail, setSendSetupEmail] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isPending, startTransition] = useTransition();
  const [currentAction, setCurrentAction] = useState("");
  const [editLocationData, setEditLocationData] = useState({});

  const { data: orgUSers } = useGetOrgUsers(id);
  const { data: orgTypes } = useFatchOrgType();
  const { data: orgLocations } = useGetOrgLocations(id);
  const { mutate: createLocation } = useCreateOrgLocation();
  const { mutate: deleteLocationMutation } = useDeleteOrgLocation();
  const { mutate: deleteOrg } = useDeleteOrg();
  const { mutate: editLocation } = useEditOrgLocation();
  const { data:customersDetails, isError: isErrorCustomers } =
    useFatchOrgDetails(id);
  const updateOrg = useUpdateOrg();

  const initialData = {
    name: customersDetails?.name || "",
    status: customersDetails?.status || "",
    type_name: customersDetails?.type_name || "",
    email: customersDetails?.email || "",
    organization_type: customersDetails?.organization_type || "",
    id: customersDetails?.id || 0,
  };

  const [orgDetails, setOrgDetails] = useState(initialData);
  const [updatedOrgDetails, setUpdatedOrgDetails] = useState(initialData);

  useEffect(() => {
    if (customersDetails) {
      setOrgDetails({ ...customersDetails });
      setUpdatedOrgDetails({ ...customersDetails });
    }

    if (isErrorCustomers) {
      navigate("/customers");
    }
  }, [customersDetails, isErrorCustomers]);

  const handleDebounceMutateOrg = (payload) => {
    updateOrg.mutate(payload);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(handleDebounceMutateOrg, 1000), []);

  const handleBlurOrg = (e) => {
    const { name, value } = e.target;

    const isEqual = Object.keys(updatedOrgDetails).every(
      (key) => updatedOrgDetails[key] === orgDetails[key]
    );

    if (!isEqual) {
      setUpdatedOrgDetails({
        ...orgDetails,
        [name]: value || "",
      });

      debounceFn({
        ...orgDetails,
        [name]: value,
      });
    }
  };

  const handleKeyDownOrg = (e) => {
    if (e.key === "Enter") {
      handleBlurOrg(e);
    }
  };

  const handleChangeOrg = (e) => {
    const { name, value } = e.target;

    setOrgDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClick = () => {
    startTransition(() => {
      navigate("/customers");
    });
  };

  const handleDeleteCustomer = () => {
    setCurrentAction("deleteCustomer");
    setModalTitle("Delete Customer?");
    setIsModalOpen(false);
  };

  const confirmDeleteCustomer = () => {
    deleteOrg(orgDetails.id);
    navigate("/customers");
  };

  const handleCreateLocation = () => {
    setCurrentAction("createLocation");
    setModalTitle("Create Location");
    setIsModalOpen(false);
  };

  const handleEmailSetup = () => {
    setCurrentAction("setUpEmail");
    setModalTitle("Send Set Up Email");
    setIsModalOpen(false);
  };

  const handleResend = () => {
    setCurrentAction("setUpEmail");
    setModalTitle("Send Set Up Mail");
    setIsModalOpen(false);
  };

  const handleEmailSubmit = (data) => {
    setIsModalOpen(true);
    setSendSetupEmail(false);
  };

  const editLocationSubmit = (data) => {
    editLocation(data);
    setIsModalOpen(true);
  };

  const handelClose = () => {
    setIsModalOpen(true);
    setCurrentAction("");
  };

  const editOrgLocation = (data) => {
    setCurrentAction("editLocation");
    setModalTitle("Edit Location");
    setEditLocationData(data);
    setIsModalOpen(false);
  };

  const handleLocationSubmit = (data) => {
    createLocation(data);
    setIsModalOpen(true);
  };

  const handelLocationDelete = () => {
    setCurrentAction("confirmDelete");
    setModalTitle("Delete Location?");
  };

  const confirmDelete = (id) => {
    deleteLocationMutation(id);
    setIsModalOpen(true);
    setCurrentAction("");
  };

  const renderModalContent = () => {
    switch (currentAction) {
      case "createLocation":
        return (
          <CreateLocation
            orgId={id}
            onClose={() => setIsModalOpen(true)}
            onSubmit={handleLocationSubmit}
          />
        );
      case "setUpEmail":
        return (
          <SetUpEmail
            orgId={id}
            onClose={() => setIsModalOpen(true)}
            onSubmit={handleEmailSubmit}
          />
        );
      case "editLocation":
        return (
          <EditLocation
            editLocationData={editLocationData}
            onClose={() => setIsModalOpen(true)}
            onSubmit={editLocationSubmit}
            onDelete={() => handelLocationDelete()}
          />
        );
      case "confirmDelete":
        return (
          <DeleteLocation
            orgId={editLocationData}
            onClose={handelClose}
            onSubmit={confirmDelete}
          />
        );
      case "deleteCustomer":
        return (
          <DeleteCustomer
            orgId={orgDetails.id}
            onClose={handelClose}
            onSubmit={confirmDeleteCustomer}
          />
        );
      default:
        return null;
    }
  };

  const getApGroupNameById = (id) =>
    ap_group.find((group) => group.id === id)?.name || null;

  return (
    <div className="">
      <div className="flex flex-row w-full bg-colors-bg-natural-surface border-b py-6 px-10 border-colors-border-natural-weak justify-between items-center">
        <div className="flex flex-row gap-6 items-center">
          <div className="px-4 py-3 hover:cursor-pointer" onClick={handleClick}>
          <Icon color="dark" width="12" height="12" name="Back" />
          </div>
          <TitleLG className="text-colors-text-natural-strong">
            {orgDetails?.name}
          </TitleLG>
        </div>

        <div className="flex flex-row ">
          {customersDetails?.name.indexOf("🔒") === -1 && (
            <Button
              children="Delete Customer"
              small
              color="default-critical"
              onClick={handleDeleteCustomer}
            />
          )}
          {customersDetails?.name.indexOf("🔒") === -1 &&
            (sendSetupEmail ? (
              <Button
                children="Send Set Up Email"
                small
                color="default-secondary"
                onClick={handleEmailSetup}
              />
            ) : (
              <Button
                children="Resend Set Up Email"
                small
                onClick={handleResend}
                color="default-secondary"
              />
            ))}

          <Button
            children="Create Location"
            small
            color="default-primary"
            iconName="Subtract"
            iconColor="blue"
            iconPosition="left"
            onClick={handleCreateLocation}
          />
        </div>
      </div>

      <div className="flex flex-col p-12 gap-12 w-[70%] mx-auto">
        <div className="bg-colors-bg-natural-surface rounded-lg divide-y divide-colors-border-natural-weak border border-colors-border-natural-weak ">
          <div className="flex items-center justify-between p-6 rounded-t ">
            <TitleLG className="text-colors-text-natural-strong">
              Customer Details
            </TitleLG>
          </div>
          <div className="flex flex-col gap-2 px-6 py-10">
            <div className="flex flex-col gap-6">
              <div className="flex flex-row gap-6 items-center justify-between">
                <TextInput
                  type="text"
                  label="Customer Name"
                  name="name"
                  className="w-full"
                  value={orgDetails.name}
                  onChange={handleChangeOrg}
                  onBlur={handleBlurOrg}
                  onKeyDown={handleKeyDownOrg}
                />
                <SelectInput
                  label="Status"
                  helpertext=""
                  name="status"
                  options={status}
                  value={orgDetails.status}
                  onChange={handleChangeOrg}
                  onBlur={handleBlurOrg}
                  onKeyDown={handleKeyDownOrg}
                />
              </div>
              <div className="flex flex-row gap-6 items-center justify-between">
                <TextInput
                  type="text"
                  label="Contact Email Address"
                  name="email"
                  value={orgDetails.email}
                  className="w-full"
                  onChange={handleChangeOrg}
                  onBlur={handleBlurOrg}
                  onKeyDown={handleKeyDownOrg}
                />
                <SelectInput
                  label="Establishment Type"
                  helpertext=""
                  name="organization_type"
                  options={orgTypes}
                  value={orgDetails?.type_name}
                  onChange={handleChangeOrg}
                  onBlur={handleBlurOrg}
                  onKeyDown={handleKeyDownOrg}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-colors-bg-natural-surface rounded-lg divide-y divide-colors-border-natural-weak border border-colors-border-natural-weak">
          <div className="flex items-center justify-between p-6 rounded-t ">
            <div className="flex flex-col gap-2">
              <TitleLG className="text-colors-text-natural-strong">
                Customer Locations
              </TitleLG>
            </div>
          </div>
          <div className="flex flex-col gap-2 px-6 py-10">
            <div className="flex flex-col gap-2">
              {orgLocations &&
                orgLocations.map((location) => (
                  <div
                    key={location?.id}
                    className="flex flex-row gap-10 py-4 px-6 items-center rounded justify-between w-full hover:bg-colors-bg-natural-inverseHover hover:cursor-pointer active:bg-colors-bg-natural-surfacePressed"
                    onClick={() => editOrgLocation(location)}
                  >
                    <div className="flex flex-row gap-10 items-center">
                      <Icon
                        color="gray"
                        width="23.97px"
                        height="24px"
                        name="Location"
                      />
                      <div className="flex flex-col gap-2">
                        <LabelMD className="text-colors-text-natural-strong font-medium">
                          {location?.name}
                        </LabelMD>
                        <BodyXS className="text-colors-text-natural-medium">
                          {location?.address}
                        </BodyXS>
                      </div>
                    </div>

                    <div className="flex flex-row items-center gap-10">
                      <BodySM className="text-left text-colors-text-natural-medium">
                        {getApGroupNameById(location?.ap_group)}
                      </BodySM>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="bg-colors-bg-natural-surface rounded-lg divide-y divide-colors-border-natural-weak border border-colors-border-natural-weak">
          <div className="flex items-center justify-between p-6 rounded-t ">
            <div className="flex flex-col gap-2">
              <TitleLG className="text-colors-text-natural-strong">
                Customer User Accounts
              </TitleLG>
            </div>
          </div>
          <Card>
            {orgUSers &&
              orgUSers.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row gap-10 py-4 px-6 items-center justify-between w-full"
                >
                  <div className="flex flex-col gap-2">
                    <LabelMD className="text-colors-brand-blue-dark font-medium">
                      {item?.first_name} {item?.last_name}
                    </LabelMD>
                    <BodyXS className="text-colors-text-natural-medium">
                      {item?.email}
                    </BodyXS>
                  </div>
                  <div className="flex flex-row items-center gap-10">
                    <BodySM className="text-left text-colors-text-natural-medium">
                      {item?.last_login || "Last active 1 hour ago"}
                    </BodySM>
                    {/* <Button
                      children="Delete"
                      small
                      color="default-critical"
                      onClick={() => handleDeleteUserClick(item.id)}
                    /> */}
                  </div>
                </div>
              ))}
          </Card>
        </div>

        <Modal
          title={modalTitle}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          {renderModalContent()}
        </Modal>
      </div>
    </div>
  );
}
