import React, {useEffect, useState} from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/common/PrivateRoute";

// Import your components without lazy loading
import Login from "./pages/login/Login";
import Registration from "./pages/singup/Registration";
import Setup from "./pages/singup/Setup";
import Resetpassword from "./pages/resetpassword/Resetpassword";
import Changepassword from "./pages/changepassword/Changepassword";
import Dashboard from "./pages/dashboard/Dashboard";
import CreateCustomer from "./pages/createCustomer/CreateCustomer";
import Logout from "./pages/logout/Logout";
// import DashboardLayout from "./layout/dashboardLayout/DashboardLayout";
import Customers from "./pages/customers/Customers";
import Account from "./pages/account/Account";
import Locations from "./pages/locations/Locations";
import AuthProvider from "components/common/AuthProvider";
import PublicRoute from "components/common/PublicRoute";
import GuestWifiSettings from "./pages/guestWiFiSettings/GuestWiFiSettings";
import Organisation from "./pages/organisation/Organisation";
import FirstTimeLogin from "./pages/firstTimeLogin/FirstTimeLogin";
import NotFound from "pages/notFound/NotFound";
import { isMobile } from 'react-device-detect';
import NotAvailableOnMobile from "pages/notAvailable/NotAvailableOnMobile";
import SignUpSuccessful from "pages/singup/SignUpSuccessful";
import { getProfile } from "api/UsersApi";



export default function RouteManager() {

  const [userRoleForAccess, setUserRoleForAccess] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (userRoleForAccess === null) {
      const fetchProfile = async () => {
        try {
          const profile = await getProfile();
          setUserRoleForAccess(profile.data.user_role);
        } catch (error) {
          console.error("Error fetching profile:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchProfile();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div></div>;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {isMobile ? (
            <Route path="*" element={<NotAvailableOnMobile />}/>
          ) : (
            <React.Fragment>
              <Route element={<PublicRoute />}>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/forgot-password" element={<Resetpassword />} />
                <Route path="/password-reset-confirm" element={<Changepassword />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/setup" element={<Setup />} />
                <Route path="/signup-successful" element={<SignUpSuccessful />}/>
                
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/organisation" element={userRoleForAccess === 'Manager' ? <NotFound /> : <Organisation />} />
                <Route path="/guest-wifi-settings" element={userRoleForAccess === 'Manager' ? <NotFound /> : <GuestWifiSettings />} />
                <Route path="/first-time-login" element={<FirstTimeLogin />} />
                <Route path="/account" element={<Account />} />
                <Route path="/dashboard" element={<Dashboard />}> 
                {/* {element prop previously used to be DashboardLayout} */}
                  <Route
                    index
                    element={
                      <Navigate
                        to={userRoleForAccess === "admin" ? "/customers" : "/dashboard"}
                      />
                    }
                  />
                  <Route element={<ProtectedRoute userRoleForAccess="admin" />}>
                    <Route path="customers" element={<Customers />} />
                    <Route path="customers/:id" element={<CreateCustomer />} />
                  </Route>
                  <Route element={<ProtectedRoute userRoleForAccess="business_owner" />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="locations" element={<Locations />} />
                  </Route>

                  
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} /> {/* Catch-all route for undefined paths */}
            </React.Fragment>
          )}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
