import { Logo } from "components";
import React, { useEffect, useState } from "react";
import useForm from "../../hooks/validation/useForm";
import {
  validateEmail
} from "../../components/utils/validationUtils";
import { usePasswordReset } from "hooks";
import { Flex, Text, Button, Link, TextField } from "@radix-ui/themes";
import * as Form from "@radix-ui/react-form";

export default function Resetpassword() {
  const [mailSent, setMailSent] = useState(true);

  const initialFormState = {
    email: "",
  };

  const {
    mutate: passwordReset, isSuccess: isSuccessMailSent,
  } = usePasswordReset();

  const validations = {
    email: (value) =>
      !value
        ? "Input your email address. For example: john@email.com"
        : !validateEmail(value)
          ? "Invalid email address"
          : "",
  };

  const { formState, errors, handleChange, handleSubmit, handleBlur } = useForm(
    initialFormState,
    validations
  );

  const handleEmailSend = async (formState) => {
    passwordReset(formState);
    
  };

  useEffect(() => {
    if (isSuccessMailSent) {
      setMailSent(false)
    }
    
  }, [isSuccessMailSent]);


  return (
    <Flex style={{ height: '100vh', width: '100vw'}}>
      <Flex
        direction="column" 
        align="stretch"
        style={{
          padding: '80px 120px 0 120px',
          backgroundImage: 'url(/LoginPageBG.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundBlendMode: 'soft-light',
          flex: '1 1 50%',
          alignItems: 'center',
      }}>
        <Logo icon="LogoWifiLight" width="40px" height="32px" color="white"/>
      </Flex>
      <Flex 
        direction="column" 
        align="stretch"
        px="7" 
        py="8"
        style={{alignItems: 'center', flex: '1 1 50%',}}>
        <Flex
          justify="center" 
          align="stretch"
          width="100%"
          style={{alignItems: 'center', flex: '1 0 0'}}>
          <Flex direction="column" gap="7" align="start" width="296px">
            <Text size="5" weight="bold" style={{display: 'flex', alignSelf: 'center'}}>
              Forgot Password
            </Text>
            {mailSent ? (
              <Form.Root onSubmit={(e) => handleSubmit(e, handleEmailSend)} style={{width: '100%'}}>
                <Flex direction="column" gap="5" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                  <Form.Field name="email" style={{width: '100%'}}>
                    <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '8px'}}>
                      <Text size="2" weight="medium">Email Address</Text>
                    </Form.Label>
                    <Form.Control asChild>
                      <TextField.Root
                        placeholder='example@email.com'
                        value={formState.email}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        className={`input ${errors.email ? 'input-error' : ''}`}
                      />
                    </Form.Control>
                    {errors.email && <Form.Message>{errors.email}</Form.Message>}
                  </Form.Field>
                  <Flex direction="column" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                    <Button type="submit" variant="solid" radius="medium" style={{alignSelf: 'stretch', cursor: 'pointer'}}>
                      Send Password Reset Email
                    </Button>
                  </Flex>
                </Flex>
              </Form.Root>
            ) : (
              <Flex direction="column" gap="4">
                <Text>Password Reset Email Sent</Text>
                <Text>Open the email and follow the steps to reset your account password.</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex gap="7" justify="center" width="100%">
          <Link size="1" weight="regular" color="gray" href="#">Terms</Link>
          <Link size="1" weight="regular" color="gray" href="#">Privacy Policy</Link>
          <Link size="1" weight="regular" color="gray" href="mailto:support@b-connect.co.uk">Support</Link>
        </Flex>
      </Flex>
    </Flex>
  );
}
