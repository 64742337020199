"use client";

import * as React from "react";
import { Text } from "@radix-ui/themes";
import { format, isAfter, isBefore, startOfDay, endOfDay } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { Cn } from "components/utils/Cn";
import { ShadButton } from "components/buttons/ShadButton";
import { Calendar } from "components/calendar/Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "components/popover/Popover";
import '../../tailwind.css';


export function DatePickerWithRange({ className, value, onDateChange, disableBeforeDate }) {
  const today = startOfDay(new Date());
  const before = startOfDay(disableBeforeDate)
  const [date, setDate] = React.useState(value || { from: null, to: null });
  React.useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  const handleDateChange = (newDate) => {
    if (newDate?.from) {
      if (!newDate.to || newDate.from.getTime() === newDate.to.getTime()) {
        newDate = {
          from: startOfDay(newDate.from), // Set start time to 00:00:00
          to: endOfDay(newDate.from), // Set end time to 23:59:59
        };
      } else {
        newDate = {
          from: startOfDay(newDate.from), // Set start time to 00:00:00
          to: endOfDay(newDate.to), // Set end time to 23:59:59
        };
      }

      if (newDate.to.getTime() === endOfDay(today).getTime()) {
        newDate.to = new Date();
      }
    } else {
      setDate({ from: null, to: null });
      return;
    }

    setDate(newDate); 
    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  return (
    <div className={Cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <ShadButton
            id="date"
            variant={"outline"} 
            className={Cn(
              "h-[32px] justify-start text-left",
              !date && "text-muted-foreground"
            )}
            style={{ 
              backgroundColor: "var(--color-solid)",
              border: "1px solid var(--gray-a7)",
              borderRadius: "var(--radius-2)",
            }}
          >
            {/* <CalendarIcon style={{color: "var(--gray-11)", width: '16px', height: '16px'}} className="mr-2" /> */}
            <Text weight="medium" style={{color: "var(--gray-11)"}}>
              {date?.from ? (
                date.to ? (
                  <React.Fragment>
                    {format(date.from, "dd LLL y")} -{" "}
                    {format(date.to, "dd LLL y")}
                  </React.Fragment>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Text>
          </ShadButton>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start" size="2">
          <Calendar
            initialFocus
            mode="range"
            selected={date}
            onSelect={handleDateChange}
            numberOfMonths={2}
            disabled={(date) => isAfter(date, today) || isBefore(date, before)}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}