import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useLocations, useGetOrgUsers, useEditOrgUser, useDeleteOrgUser, useSendInviteUser, useGetInviteUsers, useResendInviteUser, useDeleteInviteUser } from "hooks";
import { Flex, Text, Separator, Button, Tabs, Box, Dialog, TextField, Select } from "@radix-ui/themes";
import { PlusIcon } from "@radix-ui/react-icons";
import { Header, AvatarDropdownMenu } from "components";
import { useNavigate } from 'react-router-dom';
import { useTheme } from "../../context/ThemeContext";
import LocationMultiSelect from "components/locationMultiSelect/LocationMultiSelect";
import { validateEmail } from "../../components/utils/validationUtils";
import { ToastContainer, toast } from "react-toastify";
import { getProfile } from "api/UsersApi";
import { set } from 'date-fns';

const Locations = lazy(() => import('./Locations'));
const Details = lazy(() => import('./Details'));
const Users = lazy(() => import('./Users'));

export default function Organisation() {
  const navigate = useNavigate();
  const { setAppearance } = useTheme();

  const [viewerId, setViewerId] = useState(null);
  
  useEffect(() => {
    if (viewerId === null) {
      const fetchProfile = async () => {
        try {
          const profile = await getProfile();
          setViewerId(profile.data.id);
        } catch (error) {
          console.error("Error fetching profile:", error);
        }
      }; 
    fetchProfile();
  }}, [viewerId]);

  const [invalidEmailMessage, setInvalidEmailMessage] = useState({
    message: "",
    invalid: false
  });
  const { data: invitedUser, refetch: refetchInvitedUsers } = useGetInviteUsers();
  const invitedUserList = invitedUser?.map((entry) => entry);

  const [userEmail, setUserEmail] = useState("");
  const inviteUser = useSendInviteUser();
  const { initialItems, locationSelection, setLocationSelection } = useLocations(viewerId);
  const [invitationType, setInvitationType] = useState('manager');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEmailSubmission = async (e) => {
    e.preventDefault();

    if (!userEmail.trim() || !validateEmail(userEmail)) {
      setInvalidEmailMessage({
        ...invalidEmailMessage,
        message: "Input a valid email address",
        invalid: true
      });
    } else {
      try {
        const locations_list = locationSelection;
        const invitation_status = "Pending";

        if (invitationType === 'manager' && locations_list.length === 0) {
          toast.dismiss();
          toast.error("Invite Failed. Please select at least 1 location.");
          throw new Error("Invite Failed. Please select at least 1 location.");
        }

        await inviteUser.mutateAsync({
          email: userEmail,
          locations_list,
          invitation_type: invitationType,
          invitation_status
        });
        setUserEmail("");
        setIsDialogOpen(false);
        refetchInvitedUsers();
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setInvalidEmailMessage({
            ...invalidEmailMessage,
            message: "An account already exists with this email address.",
            invalid: true
          });
        }
      }
    }
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  const [locationKey, setLocationKey] = useState(0);

  const handleRoleChange = (value) => {
    setInvitationType(value);
    setLocationKey(prevKey => prevKey + 1);
    if (value === 'manager') {
      setLocationSelection(locationSelection);
    } else {
      setLocationSelection(initialItems.map(item => item.value));
    }
  };

  return (
    <React.Fragment>
      <Flex direction="column" style={{ alignItems: 'flex-start', alignSelf: 'stretch', flex: '1 0 0'}}>
        
        <Flex className='header' px="var(--space-5)" py="var(--space-4)" justify="between" style={{ alignItems: 'center', alignSelf: 'stretch', backgroundColor: "var(--color-panel-solid)", position: "sticky", zIndex:5, top:0 }}>
          
          <Flex direction="row" gap="4" align="center">
            <Text className="organisation-header" size="4" weight="bold">
              Organisation
            </Text>
          </Flex>

          <Flex align="center" gap="4">

            {/* <Button size="2" variant="surface" color="gray">
              <PlusIcon />
              <Text style={{color: "var(--gray-11)"}} size="2" weight="medium">
                Create Location
              </Text>
            </Button> */}

            <Dialog.Root 
              style={{
                display: "flex",
                height: "308px",
                width: "432px",
                padding: "var(--space-4)",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
              open={isDialogOpen}
              onOpenChange={(open) => {
                setUserEmail("");
                setInvalidEmailMessage({
                  ...invalidEmailMessage,
                  message: "",
                  invalid: false
                  })
                setIsDialogOpen(open);
              }}
            >

            <Dialog.Trigger>
              <Button size="2" variant="surface" onClick={() => setIsDialogOpen(true)}>
                <PlusIcon />
                <Text size="2" weight="medium">
                  Invite User
                </Text>
              </Button>
            </Dialog.Trigger>

            <Dialog.Content 
              className='modal-container'
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "var(--space-4)",
                alignSelf: "center",
                position: "fixed",
                inset: 0,
                overflow: "hidden",
                justifyContent: "center",
              }}
              width="432px"
              height="308px" // Height with all the entries is 308px
              align="start"
            >
            <Dialog.Title>
              <Text size="5" weight="bold" align="left" highContrast={true} >Invite User</Text>
            </Dialog.Title>

            <Flex className="slot-container" style={{alignSelf: "stretch"}} display="flex" py="0" align="start">
              <Flex className="email-fields-container" display="flex" direction="column" align="start" gap="4" flexGrow="1" flexShrink="0" flexBasis="0">
                {invalidEmailMessage.invalid === true ? 
                  <Flex className="frame-528172777" style={{alignSelf: "stretch"}} display="flex" direction="column" align="start" gap="2">
                    
                    <label className="text-field" style={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "var(--space-2)", alignSelf: "stretch"}}>
                    
                      <Text size="2" weight="medium">
                        Email Address
                      </Text>

                      <TextField.Root
                        style={{
                          alignSelf: "stretch",
                        }}
                        defaultValue=""
                        placeholder="Enter your email"
                        onChange={(e) => setUserEmail(e.target.value)}
                      />
                              
                    </label>
                    
                    <Text size="1" weight="normal" style={{color: "var(--red-11)"}}>
                      {invalidEmailMessage.message}
                    </Text>
                  </Flex>
                   :
                  <label className="text-field" style={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "var(--space-2)", alignSelf: "stretch"}}>
                    <Text size="2" weight="medium">
                      Email Address
                    </Text>
                    <TextField.Root
                      style={{
                        alignSelf: "stretch",
                      }}
                      size="2"
                      defaultValue=""
                      placeholder="Enter your email"
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                  </label>
                }
                            
                <Flex className="frame-528172758" style={{alignSelf: "stretch"}} display="flex" justify="center" align="end" gap="10px" >
                  <Flex className="frame-528172793" display="flex" direction="column" align="start" gap="2" flexGrow="1" flexShrink="0" flexBasis="0">
                    <Text size="2" weight="medium">
                      Role
                    </Text>
                    <Select.Root size="2" defaultValue="Manager" onValueChange={handleRoleChange}>
                      <Select.Trigger style={{alignSelf: "stretch"}} variant="surface" color="gray"/>
                      <Select.Content>
                        <Select.Group>
                          <Select.Item value="Owner">Owner</Select.Item>
                          <Select.Item value="Manager">Manager</Select.Item>
                        </Select.Group>
                      </Select.Content>
                    </Select.Root>
                  </Flex>
                  <Flex className="frame-528172794" display="flex" direction="column" align="start" gap="2" flexGrow="1" flexShrink="0" flexBasis="0">
                    <Text size="2" weight="medium">
                      Locations
                    </Text>
                    <LocationMultiSelect
                      key={locationKey}
                      toggleFlexGrow={true}
                      options={initialItems}
                      defaultValue={locationSelection}
                      onValueChange={setLocationSelection}
                      disabled={invitationType === 'owner'}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex className="button-container" style={{alignSelf: "stretch"}} display="flex" py="0" justify="end" gap="3">
              <Dialog.Close asChild onClick={handleCancel}>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Dialog.Close asChild onClick={handleEmailSubmission}>
                <Button>
                  Send Invite
                </Button>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Root>
            
        <Flex display="flex" align="center" gap="4">
          <AvatarDropdownMenu />
        </Flex>
      </Flex>
     
        </Flex>
        
        <Separator size="4" orientation="horizontal" style={{ position: 'sticky', top: '63px', zIndex: 5 }}/>
          
       
        
        
      <Tabs.Root defaultValue="users" style={{ display: 'flex', alignSelf: 'stretch', flexDirection: 'column', 
        height:"100%"}}>
        <Tabs.List size="2" style={{ display: 'flex', padding: '2px var(--space-5)', 
          alignItems: 'center', backgroundColor: 'var(--color-panel-solid)'
        }}>
          {/* <Tabs.Trigger value="locations">Locations</Tabs.Trigger> */}
          <Tabs.Trigger value="users">Users</Tabs.Trigger>
          <Tabs.Trigger value="details">Details</Tabs.Trigger>
        </Tabs.List>
                
        <Flex style={{ height: '100%', alignSelf: 'stretch', justifyContent: 'center' }}>
          {/* <Tabs.Content value="locations">
            <Suspense>
              <Locations />
            </Suspense>
          </Tabs.Content> */}
          <Tabs.Content value="users">
            <Suspense>
              <Users viewerId={viewerId}/>
            </Suspense>
          </Tabs.Content>
          <Tabs.Content value="details">
            <Suspense>
              <Details />
            </Suspense>
          </Tabs.Content>
        </Flex>
      </Tabs.Root>
      </Flex>

      <ToastContainer
        bodyStyle={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "8px 12px",
          flexShrink: "1",
        }}
        toastStyle={{
          backgroundColor: "var(--gray-a12)",
          color: "var(--color-panel)",
          fontSize: "var(--font-size-2)",
          fontWeight: "var(--font-weight-medium)",
          boxShadow: "0px 12px 32px -16px var(--gray-a3), 0px 8px 40px var(--black-a1)",
          borderRadius: 4,
          wordWrap: "break-word",
        }}
        position="bottom-center"
        autoClose={6000}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        hideProgressBar
        limit={1}
      />
    </React.Fragment>
  );
}