import React from 'react';
import { Flex, Text } from "@radix-ui/themes";

const NewReturningLegend = (props) => {
  let { payload } = props;

  // Sort the payload so that "new" appears before "returning"
  payload = payload.slice().sort((a, b) => {
    if (a.value.toLowerCase() === 'new') return -1;
    if (b.value.toLowerCase() === 'new') return 1;
    return 0;
  });

  // Swap the first and second entries
  if (payload.length > 1) {
    [payload[0], payload[1]] = [payload[1], payload[0]];
  }

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      gap: '16px', 
      alignSelf: 'stretch' 
    }}>
      {payload.map((entry, index) => (
        <Flex key={index} display="flex" align="center" gap="4px">
          <div style={{ backgroundColor: entry.color, height: "8px", width: "8px", borderRadius: "4px"}}/>
          <Text size="1" weight="regular" style={{ color: "var(--gray-11)" }}>
            {entry.value.charAt(0).toUpperCase() + entry.value.slice(1)} {/* Capitalize the first letter */}
          </Text>
        </Flex>
      ))}
    </div>
  );
};

export default NewReturningLegend;