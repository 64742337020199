import { Logo } from 'components';
import { useNavigate } from 'react-router-dom';
import React, { useTransition } from 'react';
import useForm from "../../hooks/validation/useForm";
import { validateEmail } from "../../components/utils/validationUtils";
import { useLogin } from 'hooks';
import { Flex, Text, Button, Link, TextField } from '@radix-ui/themes';
import * as Form from "@radix-ui/react-form";
import { getProfile } from "api/UsersApi";

export default function Login() {
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const userLogin = useLogin();
  
  const initialFormState = {
    email: "",
    password: "",
  };

  const validations = {
    email: (value) =>
      !value
        ? "Input your email address."
        : !validateEmail(value)
        ? "Invalid email address"
        : "",
    password: (value) =>
      !value
        // ? "Input a password"
        // : !validatePassword(value)
        ? "Input your password."
        : "",
  };

 

  const { formState, errors, handleChange, handleSubmit, handleBlur,setErrors  } = useForm(
    initialFormState,
    validations
  );

  
  const handleLoginSubmit = async (formState) => {
    try {
      await userLogin.mutateAsync(formState); 
      const profile = await getProfile();

      const userRole = profile.data.user_role;
      const lastLogin = profile.data.last_login;
      const isFirstLogin = profile.data.is_first_login;
  
      if (userRole === 'admin') {
        navigate('/customers');
      } else {
        if (isFirstLogin === true && (lastLogin === null || lastLogin === 'null')) {
          navigate('/first-time-login');
        } else {
          navigate('/dashboard');
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrors({
          password: error.response.data.error
        });
      }
    }
  };


 


  const handleClick = () => {
    startTransition(() => {
      navigate('/forgot-password');
    });
  };

  return (
    <Flex style={{ height: '100vh', width: '100vw'}}>
      {/* Background Image and  Logo Half*/}
      <Flex 
        direction="column" 
        align="stretch"
        style={{
          padding: '80px 120px 0 120px',
          backgroundImage: 'url(/LoginPageBG.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundBlendMode: 'soft-light',
          flex: '1 1 50%',
          alignItems: 'center',
      }}>
        <Logo icon="LogoBWifi" width="40px" height="32px" color="white"/>
      </Flex>
      {/* Sign In Half */}
      <Flex 
        direction="column" 
        align="stretch"
        px="7" 
        py="8"
        style={{alignItems: 'center', flex: '1 1 50%',}}>
        <Flex
          justify="center" 
          align="stretch"
          width="100%"
          style={{alignItems: 'center', flex: '1 0 0'}}>
          <Flex direction="column" gap="7" align="start" width="296px">
            <Text size="5" weight="bold" style={{display: 'flex', alignSelf: 'center'}}>
              Sign In
            </Text>
            <Form.Root onSubmit={(e) => handleSubmit(e, handleLoginSubmit)} style={{width: '100%'}}>
              <Flex direction="column" gap="5" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                <Flex direction="column" gap="4" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                  <Form.Field name="email" style={{width: '100%'}}>
                    <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '8px'}}>
                      <Text size="2" weight="medium">Email Address</Text>
                    </Form.Label>
                    <Form.Control asChild>
                      <TextField.Root
                        type="email"
                        placeholder='example@email.com'
                        value={formState.email}
                        onChange={handleChange}
                        className={`input ${errors.email ? 'input-error' : ''}`}
                      />
                    </Form.Control>
                    {errors.email &&
                    <Form.Message>
                      <Text size="1" weight="regular" color="red">
                        {errors.email}
                      </Text>
                    </Form.Message>}
                  </Form.Field>
                  <Form.Field name="password" style={{width: '100%'}}>
                    <Form.Label style={{display: 'flex', flexDirection: 'column', marginBottom: '8px'}}>
                      <Text size="2" weight="medium">Password</Text>
                    </Form.Label>
                    <Form.Control asChild>
                      <TextField.Root
                        type="password"
                        value={formState.password}
                        onChange={handleChange}
                        className={`input ${errors.password ? 'input-error' : ''}`}
                      />
                    </Form.Control>
                    {errors.password &&
                    <Form.Message>
                      <Text size="1" weight="regular" color="red">
                        {errors.password}
                      </Text>
                    </Form.Message>}
                  </Form.Field>
                </Flex>
                <Flex direction="column" gap="2" style={{alignItems: 'flex-start', alignSelf: 'stretch'}}>
                  <Button type="submit" variant="solid" radius="medium" style={{alignSelf: 'stretch', cursor: 'pointer'}}>
                    Sign In
                  </Button>
                  <Button type="button" onClick={handleClick} variant="outline" radius="medium"
                    size="2" disabled={isPending} style={{alignSelf: 'stretch', cursor: 'pointer'}}>
                    Forgotten Your Password?
                  </Button>
                </Flex>
              </Flex>
            </Form.Root>
            <Text size="2" weight="regular" style={{display: 'flex', alignSelf: 'center'}}>
              Don't have an account?&nbsp;
              <Link color="gray" underline="always" href='https://b-connect.co.uk/#start-trial' highContrast>
                Start Free Trial
              </Link>
            </Text>
          </Flex>
        </Flex>
        <Flex gap="7" justify="center" width="100%">
          <Link size="1" weight="regular" color="gray" href="#">Terms</Link>
          <Link size="1" weight="regular" color="gray" href="#">Privacy Policy</Link>
          <Link size="1" weight="regular" color="gray" href="mailto:support@b-connect.co.uk">Support</Link>
        </Flex>
      </Flex>
    </Flex>
  )
}
