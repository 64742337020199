import * as React from "react";
import { Header, AvatarDropdownMenu } from "components";
import { Flex, Text, Separator } from "@radix-ui/themes";
export default function Account() {

  return (
    <React.Fragment>
      <div style={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "var(--color-panel-solid)" }}>
        <Header >
          <Flex display="flex" align="center" gap="4">
            <Text className="" size="4" weight="bold">
              Guest Wi-Fi
            </Text>
          </Flex>
          <Flex display="flex" align="center" gap="4">
            <div>
              <AvatarDropdownMenu/>
            </div>
          </Flex>
        </Header>
        <Separator size="4" orientation="horizontal" />
      </div>
      <Flex
        display="flex"
        direction="column"
        align="center"
        justify="center"
        height="80vh"
        gap="2"
      >
        <Text
          size="5"
          weight="medium"
          style={{
            color: 'var(--gray-12)',
          }}
        >
          Profile Coming Soon
        </Text>
        <Text
          size="2"
          weight="regular"
          style={{
            color: 'var(--gray-12)',
          }}
        >
          Thank you for your patience.
        </Text>
      </Flex>
    </React.Fragment>

  )
}
