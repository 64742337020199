import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptSignIn } from "../../api/AuthApi";
import { useDispatch } from "react-redux";
import {
  setAuthTokens
} from "../../features/auth/authSlice";
import params from "../../configs/params";

import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = params.encryptionKey;

const encryptData = (data) => {
  // Generate a random IV for each encryption
  const iv = CryptoJS.lib.WordArray.random(16);
  // Encrypt data with AES in CBC mode
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Combine IV and ciphertext, then convert to base64
  const encryptedMessage = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  
  return encryptedMessage;
};


export const useLogin = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: "login",
    mutationFn: async (values) => {
      try {
        const encryptedValues = encryptData(values);
        const res = await attemptSignIn({ data: encryptedValues });
        return res.data;
      } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
      }
    },
    onSuccess: async (data) => {
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      localStorage.setItem(
        "access_token_expiration",
        data.access_token_expiration
      );
      localStorage.setItem(
        "refresh_token_expiration",
        data.refresh_token_expiration
      );
      dispatch(
        setAuthTokens({
          accessToken: data.access,
          refreshToken: data.refresh,
          accessTokenExpiration: data.access_token_expiration,
          refreshTokenExpiration: data.refresh_token_expiration,
        })
      );
    },
    onError: (error) => {
    },
    onSettled: () => {},
  });
};
