import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  if (accessToken || refreshToken) {
    return {
      auth: true
    };
  } else {
    return {
      auth: false
    };
  }
};

const ProtectedRoute = () => {
  const { auth } = useAuth();

  if (!auth) {
    return <Navigate to="/login" />;
  }


  return <Outlet />;
};

export default ProtectedRoute;