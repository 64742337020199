import React from "react";
import PropTypes from "prop-types";
import { LabelMD, BodyXS } from "components/utils/Typography";
import { useId } from "react";
import { Icon } from "components/tokens/Icon";
import { Cn } from "components/utils/Cn";

/**
 * Renders a text input field with optional error and help text.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.label - The label for the input.
 * @param {string} [props.helptext] - The help text for the input.
 * @param {boolean} [props.error] - Flag indicating if there's an error.
 * @param {boolean} [props.required] - Flag indicating if this field is required.
 * @param {string} [props.icon] - The name of the icon.
 * @param {string} [props.className] - Additional class names.
 * @param {Object} [props] - Additional props for the input element.
 * @returns {JSX.Element} The rendered text input field.
 */

export function TextInput({
  label,
  helptext = "",
  error = false,
  className = "",
  required = false,
  search,
  icon = "",
  optional,
  ...props
}) {
  const htmlId = useId();

  return (
    <div className={Cn("flex flex-col relative", search ? "" : "gap-2", className)}>
      <div className={Cn("flex flex-row  items-center gap-2")}>
        <LabelMD
          className={Cn(
            error
              ? "text-colors-text-critical-strong "
              : "text-colors-text-natural-strong"
          )}
          htmlFor={htmlId}
        >
          {label}
        </LabelMD>
        {required && (
          <BodyXS className={Cn("text-colors-text-natural-medium")}>
            (Required)
          </BodyXS>
        )}
         {optional && (
          <BodyXS className={Cn("text-colors-text-natural-medium")}>
            (Optional)
          </BodyXS>
        )}
      </div>
      <div className="relative">
        {icon && (
          <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
            <Icon width="12" height="12" name={icon} color="gray" />
          </div>
        )}
        <input
          id={htmlId}
          tabIndex={0}
          className={Cn([
            "input-text border-colors-bg-natural-weak border bg-colors-bg-natural-weak rounded-lg text-body-md ",
            error ? "red" : "",
            icon ? "!ps-8" : "",
          ])}
          {...props}
        />
      </div>
      {helptext && (
        <BodyXS
          className={Cn(
            error ? "text-colors-text-critical-strong absolute bottom-[-20px]" : "text-colors-text-natural-medium"
          )}
        >
          {helptext}
        </BodyXS>
      )}
    </div>
  );
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  helptext: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default TextInput;
