import { useQuery } from '@tanstack/react-query';
import { attemptGetWirelessLAN } from '../../api/OrgApi';

const fetchWirelessLAN = async (organisationID) => {;
  return await attemptGetWirelessLAN(organisationID).then((res) => res.data)
};

export const useGetWirelessLAN = (organisationID) => {
  return useQuery({ 
    queryKey: ['wireless-lan', organisationID], 
    queryFn: () => fetchWirelessLAN(organisationID),
    enabled: !!organisationID,
    staleTime: 0, // Mark data as stale immediately to ensure a fresh fetch
    cacheTime: 1000 * 60 * 5, // Cache data for 5 minutes (adjust as needed)
    refetchOnWindowFocus: true, // Refetch when window regains focus
    refetchOnReconnect: true, // Refetch if network connection is lost and re-establishe
   });
};