import React from 'react';
import { Text, Flex, Badge, Tooltip } from "@radix-ui/themes";
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { useNavigate } from "react-router-dom";

export function LiveStatCard({ title, value, subValue, badge, isLast, userRole }) {
  var conditionalView;

  const navigate = useNavigate();


  const handleOnlineOfflineBadgeClick = () => {
    if(userRole === 'Owner') navigate("/guest-wifi-settings");
  };

  if (badge === true || value === null) {
    conditionalView = (
      <Flex style={{ flex: "1 0 0" }} display="flex" align="center" gap="2">
        {value !== 0 && (
          <Tooltip content="Ensure your Wi-Fi Access Point is securely connected to your main router using a PoE injector.">
            <Badge variant="soft" color="red" size="1" onClick={handleOnlineOfflineBadgeClick} style={{ cursor: "pointer" }}>
              <InfoCircledIcon style={{ width: "12px", height: "12px", color: "var(--red-a11)" }} />
              <Text size="2" weight="medium">
                {value} Offline
              </Text>
            </Badge>
          </Tooltip>
        )}
        {subValue !== 0 && (
          <Badge variant="soft" color="green" size="1" onClick={handleOnlineOfflineBadgeClick} style={{ cursor: "pointer" }}>
            <Text size="2" weight="medium">
              {subValue} Online
            </Text>
          </Badge>
        )}
      </Flex>
    );
  } else {
    if (subValue === null) {
      conditionalView = (
        <Text color="blue" size="6" weight="bold">
          {value}
        </Text>
      );
    } else {
      conditionalView = (
        <Flex display="flex" align="center" gap="2">
          <Text color="blue" size="6" weight="bold">
            {value}
          </Text>
          <Text color="blue" size="2" weight="regular">
            {subValue}
          </Text>
        </Flex>
      );
    }
  }

  return (
    <React.Fragment>
      <Flex
        style={{
          display: "flex",
          padding: "var(--space-4) var(--space-5)",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "var(--space-4)",
          flex: "1 0 0",
          alignSelf: "stretch",
          borderRight: isLast ? "none" : "1px solid var(--gray-a6)",
        }}
      >
        <Flex style={{ alignSelf: "stretch" }} display="flex" align="center" gap="2">
          <Badge variant="soft" size="1">
            Live
          </Badge>
          <Text size="1" weight="medium" color="blue">
            {title}
          </Text>
        </Flex>
        {conditionalView}
      </Flex>
    </React.Fragment>
  );
}