import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptEditOrgUser } from '../../api/OrgApi';
import { toast } from 'react-toastify';

export const useEditOrgUser = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationKey: 'edit-org-user',
    mutationFn: ({ owner_id, data }) => attemptEditOrgUser(owner_id, data),
    onMutate: async ({ owner_id, data }) => {
      // Optional: Implement optimistic update logic here
    },
    onSuccess: (data, variables) => {
      const type = Object.keys(variables.data)[1];
      let message = "User Updated";
      if (type === "role") {
        message = "Role Updated";
      } else if (type === "locations") {
        message = "Location Access Updated";
      }
      toast.dismiss();
      toast.success(message, {
        icon: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["org-users"],
      });
    },
    onError: (error) => {
      toast.dismiss();
      toast.error("Update Failed", {
        icon: false,
      });
    },
  });
};
