import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { DateTime } from 'luxon';
import { Container } from "@radix-ui/themes";
import { useTheme } from "../../context/ThemeContext";
import './ChartColours.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const getCSSVariable = (variable) => 
  getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

export const GuestConnectionsByDayOfWeekChart = ({ data, startTimeMilliseconds, endTimeMilliseconds, timezone }) => {

  const { appearance } = useTheme();
  const [fontColor, setFontColor] = useState('');
  const [chartReturningColor, setChartReturningColor] = useState('');
  const [chartNewColor, setChartNewColor] = useState('');
  const [gridColour, setGridColour] = useState('');
  const [chartPointsBgColour, setChartPointsBgColour] = useState('');

  useEffect(() => {
    const fontColourLight = getCSSVariable('--chart-font-colour-light');
    const fontColourDark = getCSSVariable('--chart-font-colour-dark');
    const neutralColour = getCSSVariable('--chart-neutral-colour');
    const positiveColour = getCSSVariable('--chart-positive-colour');
    const gridColourLight = getCSSVariable('--chart-grid-colour-light');
    const gridColourDark = getCSSVariable('--chart-grid-colour-dark');
    const chartPointsBgColourLight = getCSSVariable('--chart-points-bg-colour-light');
    const chartPointsBgColourDark = getCSSVariable('--chart-points-bg-colour-dark');

    if (appearance === 'dark') {
      setFontColor(fontColourDark);
      setGridColour(gridColourDark);
      setChartPointsBgColour(chartPointsBgColourDark);
    } else {
      setFontColor(fontColourLight);
      setGridColour(gridColourLight);
      setChartPointsBgColour(chartPointsBgColourLight);
    }
    setChartReturningColor(neutralColour);
    setChartNewColor(positiveColour);
  }, [appearance]);

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  let processed_chart_data = Array.from({ length: 7 }, (_, day) => ({
    day,
    new: 0,
    returning: 0,
    total: 0,
  }));

  if (data && data.chart && data.chart.length > 0) {
    let chart_array = data.chart;
    let returning_customer_array = data.history;

    for (let event of chart_array) {
      const eventStart = DateTime.fromMillis(event.connection_start_time).setZone(timezone);
      const dayOfWeek = eventStart.weekday - 1; // Luxon weekday is 1 (Monday) to 7 (Sunday)

      if (returning_customer_array.includes(event.client_mac_address)) {
        processed_chart_data[dayOfWeek].returning++;
      } else {
        processed_chart_data[dayOfWeek].new++;
      }
    }

    for (let i = 0; i < processed_chart_data.length; i++) {
      processed_chart_data[i].total = processed_chart_data[i].new + processed_chart_data[i].returning;
    }
  }

  const chartData = {
    labels: daysOfWeek,
    datasets: [
      {
        label: 'New',
        data: processed_chart_data.map((entry) => entry.new),
        backgroundColor: chartNewColor,
        borderRadius: 4,
      },
      {
        label: 'Returning',
        data: processed_chart_data.map((entry) => entry.returning),
        backgroundColor: chartReturningColor,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItems) {
            return tooltipItems[0].label;
          },
          footer: function (tooltipItems) {
            const dataset = tooltipItems[0].chart.data.datasets;
            const newValue = dataset[0].data[tooltipItems[0].dataIndex];
            const returningValue = dataset[1].data[tooltipItems[0].dataIndex];
            const total = newValue + returningValue;
            return `Total: ${total}`;
          },
          labelColor: function(context) {
            return {
              borderColor: context.dataset.backgroundColor,
              backgroundColor: context.dataset.backgroundColor, 
            };
          }
        },
        bodyFont: {
          family: 'Figtree',
        },
        titleFont: {
          family: 'Figtree',
        },
        footerFont: {
          family: 'Figtree',
        },
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex, // Reverse the order of the tooltip items
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: fontColor,
          font: {
            family: 'Figtree',
            size: 12,
          },
          padding: 8,
          callback: function(value, index, values) {
            return this.getLabelForValue(value);
          },
          maxRotation: 0,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        position: 'right',
        ticks: {
          color: fontColor,
          font: {
            family: 'Figtree',
            size: 12,
          },
          padding: 8,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        }
      },
    },
  };

  return (
      <Bar data={chartData} options={options}/>
  );
};