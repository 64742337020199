import { useMutation } from "@tanstack/react-query";
import { attemptInviteUser } from '../../api/UsersApi';
import { toast } from 'react-toastify'; 

export const useSendInviteUser = () => {
  return useMutation({
    mutationKey: 'inviteUser',
    mutationFn: async (values) => attemptInviteUser(values),
    onMutate: async (data) => {
    },
    
    onSuccess: (data) => {
     toast.dismiss();
     toast.success("Invite Sent", {
      icon: false
     })
      
    },
    onError: (error) => {
    },
    onSettled: async(_, error) => {
      
    }
  });
};
