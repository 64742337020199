import React from "react";
import { Flex, Text } from "@radix-ui/themes";

const NotFound = () => {
  return (
    <React.Fragment>
      <Flex p="5" direction="column" gap="5" height="100%"
          style={{alignSelf: 'stretch', alignItems: 'center', flex: '1 0 0', justifyContent: 'center'}}>
          <Flex direction="column" gap="2" style={{alignItems: 'center'}}>
              <Text size="5" weight="medium">
                  404
              </Text>
              <Text size="2" weight="regular" color="gray" style={{width:'296px', textAlign:'center'}}>
                  Page not found.
              </Text>
          </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default NotFound;