import React from 'react';
import { Cn } from 'components/utils/Cn';
export function Pillbadge({pillname, primary, secondary, critical, default: isDefault,...props}) {

  let color = '';

  if (isDefault && primary) {
    color = 'text-colors-brand-blue bg-colors-brand-blue-16';
} else if (isDefault && secondary) {
  color = 'text-colors-brand-blue-dark bg-colors-brand-blue-dark-16';
} else if (isDefault && critical) {
  color = 'text-colors-brand-red bg-colors-brand-red-16';
} else if (primary) {
  color = 'text-colors-brand-white bg-colors-brand-blue';
} else if (secondary) {
  color = 'text-colors-brand-white bg-colors-brand-blue-dark';
} else if (critical) {
  color = 'text-colors-brand-white bg-colors-brand-red';
} else if (pillname === 'active') { 
  color = 'text-colors-text-primary-strong bg-colors-bg-primary-medium';
} else if (pillname === 'onboarding') { 
  color = 'text-colors-text-natural-inverse bg-colors-bg-primary-strong';
} else if (pillname === 'depricated') { 
  color = 'text-colors-text-natural-strong bg-colors-bg-natural-medium';
}

  return (
    <div className='m-2'>
        <span {...props}  className={Cn("text-sm text-center font-medium px-2 py-1 rounded-full hover:cursor-pointer", color)}>{pillname.replace(/^./, pillname[0].toUpperCase())}</span>
    </div>
  )
}
