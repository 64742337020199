import React, { useEffect, useState } from "react";
import {DropdownMultiSelect, Dropdown, TextInput, SelectInput, Button, Pillbadge, Modal} from "components";
import { BodySM, LabelMD } from "components/utils/Typography";
import { useFatchOrgType, useFatchOrgs, useCreateOrg } from "hooks";
import { useNavigate } from "react-router-dom";
import { sortList, status } from "mock";
import moment from "moment";

export default function Customers() {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [formState, setFormState] = useState({ name: "", email: "", organisation_type: "" });
  const [errors, setErrors] = useState({ customerName: "", email: "" });
  const { data: orgTypes } = useFatchOrgType();
  const { data: customers } = useFatchOrgs();
  const [sortedCustomers, setSortedCustomers] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (customers) {
      handleSelect("Newest First")
    }
  }, [customers]);

  const orgCreate = useCreateOrg();

  const validateInputs = () => {
    const newErrors = {};

    if (!formState.name.trim()) {
      newErrors.name = "Input your name.";
    }

    if (formState.organisation_type === "") {
      newErrors.organisation_type = "Establishment Type is required.";
    }

  

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: name === 'organisation_type' ? parseInt(value, 10) : value
    }));
  };

  const handleCreate = () => {
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(true);
    setErrors({ name: "", email: "", organisation_type: "" });
    setFormState({ name: "", email: "", organisation_type: "" });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      orgCreate.mutate(formState, {
        onSuccess: (data) => {
          setIsModalOpen(true);
          setFormState({ name: "", email: "", organisation_type: "" });
          navigate(`/customers/${data.data.id}`);
        },
        onError: (error) => {
          setIsModalOpen(true);
        }
      });
    } else {
      setIsModalOpen(false);
    }
  };

  const handleSelect = (value) => {
    let sortedData = [...customers];
    switch (value) {
      case "Newest First":
        sortedData.sort((a, b) => b.id - a.id);
        break;
      case "Oldest First":
        sortedData.sort((a, b) => a.id - b.id);
        break;
      case "Alphabetical (A-Z)":
        sortedData.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case "Alphabetical (Z-A)":
        sortedData.sort((a, b) => b.name.localeCompare(a.name));
        break;
      default:
        break;
    }
    setSortedCustomers(sortedData);
  };

  const handleSelectStatus = (items) => {
    const selectedStatuses = items.map(item => item.name.toLowerCase());
    const filteredData = customers.filter((item) => selectedStatuses.includes(item.status));
    if (selectedStatuses.length === 0) {
      setSortedCustomers(customers);
    } else {
      
      setSortedCustomers(filteredData);
    }
  }

  const handleSearch = (searchText) => {
    const normalizedSearchText = String(searchText).toLowerCase().trim();
    const filteredData = customers.filter((item) =>
      item.name.toLowerCase().includes(normalizedSearchText)
    );
    setSortedCustomers(filteredData);
  };

  const modalContent = (
    <form
      className="flex flex-col divide-y divide-colors-border-natural-weak"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-6 p-6">
        <div className="flex flex-row gap-6 items-center justify-between">
          <TextInput
            type="text"
            label="Customer Name"
            name="name"
            value={formState.name}
            onChange={handleChange}
            className="w-full"
            error={!!errors.name}
            helptext={errors.name || ""}
          />
          <SelectInput
            label="Establishment Type"
            name="organisation_type"
            placeholder="Select"
            options={orgTypes}
            value={formState.organisation_type || ""}
            onChange={handleChange}
            error={!!errors.organisation_type}
            helpertext={errors.organisation_type || ""}
          />
        </div>
        <TextInput
        optional
          type="text"
          label="Contact Email Address"
          name="email"
          value={formState.email}
          onChange={handleChange}
          className="w-full"
          error={!!errors.email}
          helptext={errors.email || ""}
        />
      </div>
      <div className="flex flex-row justify-end items-center gap-3 p-6">
        <Button
          type="button"
          small
          children="Cancel"
          color="default-primary"
          onClick={handleCloseModal}
        />
        <Button type="submit" small children="Create" color="primary" />
      </div>
    </form>
  );

 

  return (
    <div className="">
      <div className="flex flex-row w-full bg-colors-bg-natural-surface border-b py-[1.4rem] px-10 border-colors-border-natural-weak justify-between items-center">
        <div className="flex flex-row">
          <form className="flex flex-row gap-6 items-center">
            <TextInput
              label=""
              type="text"
              placeholder="Search"
              search="true"
              icon="MagnifyingGlass"
              value={searchText}
              className="w-[296px]"
              onChange={(e) => {
                setSearchText(e.target.value);
                handleSearch(e.target.value);
              }}
            />
            <Dropdown
              label="Newest First"
              icon="Sorting"
              items={sortList}
              onSelect={(item) => handleSelect(item.label)}
            />
            <DropdownMultiSelect
              label="All Status"
              items={status}
              onSelect={(items) => handleSelectStatus(items)}
            />
          </form>
        </div>
        <div className="">
          <Button
            children="Create Customer"
            small
            color="default-primary"
            iconName="Subtract"
            iconColor="blue"
            iconPosition="right"
            onClick={handleCreate}
          />
        </div>
      </div>

      <div className="p-10 ">
      {sortedCustomers && sortedCustomers.length > 0 ? (
  <table className="flex justify-center flex-col p-4 bg-colors-bg-natural-surface rounded-lg border border-colors-border-natural-weak">
    <tbody className="w-full">
      {sortedCustomers.map((customer) => (
        <tr
          className="rounded hover:bg-colors-bg-natural-inverseHover hover:cursor-pointer active:bg-colors-bg-natural-surfacePressed flex px-6 py-4 items-center"
          key={customer.id}
          onClick={() => navigate(`/customers/${customer.id}`)}
        >
          <td className="hover:cursor-pointer w-[22%]" align="left">
            <LabelMD className="text-colors-text-natural-strong hover:cursor-pointer">
              {customer.name}
            </LabelMD>
          </td>
          <td align="left" className="w-[14%]">
            <BodySM className="text-colors-text-natural-medium">
              {customer.type_name}
            </BodySM>
          </td>
          <td align="left" className="w-[12%]">
            <BodySM className="text-colors-text-natural-medium">
              {customer.count_users} Users
            </BodySM>
          </td>
          <td align="left" className="w-[13%]">
            <BodySM className="text-colors-text-natural-medium">
              {customer.count_locations} Locations
            </BodySM>
          </td>
          <td align="left" className="w-[14%]">
            <BodySM className="text-colors-text-natural-medium">
              {customer.count_access_points} Access Points
            </BodySM>
          </td>
          <td align="left" className="w-[15%]">
            <BodySM className="text-colors-text-natural-medium">
              Created on {customer?.created_at ? moment(customer?.created_at).format('DD MMM, YYYY') : ''}
            </BodySM>
          </td>
          <td align="right" className="w-[12%]">
            <Pillbadge pillname={customer.status} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <div className="flex justify-center flex-col p-4 bg-colors-bg-natural-surface rounded-lg border border-colors-border-natural-weak text-center">
   <BodySM className="text-colors-text-natural-medium">No Results. Try adjusting your search query or filters.</BodySM> 
  </div>
)}
  
        
      </div>

      <Modal
        title={"Create Customer"}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
