import { useMutation } from "@tanstack/react-query";
import { attemptResendInviteUser } from '../../api/UsersApi';
import { toast } from 'react-toastify'; 

export const useResendInviteUser = () => {
  return useMutation({
    mutationKey: 'inviteUser',
    mutationFn: async (data) => attemptResendInviteUser(data),
    onMutate: async (data) => {
    },
    onSuccess: (data) => {
     toast.success("Invite resent", {
      icon: false
     })
      
    },
    onError: (error) => {
      toast.error("Resent failed", {
        icon: false
       })
    },
    onSettled: async(_, error) => {
      
    }
  });
};
